import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin, Tooltip } from 'antd';
// import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import HighlightComponent from 'components/HighlightComponent';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import ComponentToPrint from '../../add-billing/components/billing-component-to-print';
import BillingsListPresentational from './billings-list-presenatational';
import { formQueryStringFromObject } from 'helpers';
// import { sendGetRequest } from 'redux/sagas/utils';
// import { deleteApi } from 'redux/sagas/deleteApiSaga';
// import { CloseOutlined, EditOutlined } from '@ant-design/icons';
// import { deleteApi } from 'redux/sagas/deleteApiSaga';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const BillingsListFunctional = React.memo(() => {
	const billings = useSelector((state) => state?.billingsRedux?.billings);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
	});
	const [dates, setDates] = useState({
		startDate: moment(),
		endDate: moment(),
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState('');
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState(billings);
	const [items, setItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [
		billItemLoading,
		// setBillItemLoading
	] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const getOrders = useCallback(() => {
		const string = formQueryStringFromObject({
			fromDate: moment(dates?.startDate).format(DATE_FORMAT.YYYY_MM_DD),
			toDate: moment(dates?.endDate).format(DATE_FORMAT.YYYY_MM_DD),
		});
		dispatch(getApi(ACTIONS.GET_BILLINGS, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}&${string}`));
	}, [dispatch, globalRedux?.selectedOrganization?._id, dates]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.billNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.billingDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.deliveryDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.status || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerId?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleViewRow = (row) => {
		console.log('🚀 ~ file: billings-list-functional.js:62 ~ handleViewRow ~ row:', row);
		setSelectedViewRow(row?.items);
	};

	const column = [
		{
			title: '#',
			dataIndex: 'billNumber',
			key: 'billNumber',
			width: '5%',
			sorter: (a, b) => a?.billNumber - b?.billNumber,
			align: 'center',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Customer',
			dataIndex: 'customerId',
			key: 'customerId',
			width: '15%',
			sorter: (a, b) => a?.customerId?.displayName?.localeCompare(b?.customerId?.displayName),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.displayName || ''}
				/>
			),
		},
		// {
		// 	title: 'Bill No',
		// 	dataIndex: 'billNumber',
		// 	key: 'billNumber',
		// 	width: '10%',
		// 	sorter: (a, b) => a?.billNumber - b?.billNumber,
		// 	render: (value) => (
		// 		<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.toString() || ''} />
		// 	),
		// },
		{
			title: 'Bill Date',
			dataIndex: 'billDate',
			key: 'billDate',
			width: '10%',
			sorter: (a, b) => new Date(a.billDate) - new Date(b.billDate),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Delivery Date',
			dataIndex: 'deliveryDate',
			key: 'deliveryDate',
			width: '10%',
			sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Payment Status',
			dataIndex: 'paymentStatus',
			key: 'paymentStatus',
			width: '10%',
			sorter: (a, b) => a?.paymentStatus?.localeCompare(b?.paymentStatus),
		},
		{
			title: 'Bill Amount',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '10%',
			sorter: (a, b) => a?.totalAmount - b?.totalAmount,
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={parseFloat(value)?.toFixed(2)}
				/>
			),
		},
		{
			title: 'Stain',
			dataIndex: 'remark3Id',
			key: 'remark3Id',
			width: '15%',
			sorter: (a, b) => a?.remark3Id?.remark?.localeCompare(b?.remark3Id?.remark),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.remark || ''}
				/>
			),
		},
		{
			title: 'Damage',
			dataIndex: 'remark4Id',
			key: 'remark4Id',
			width: '15%',
			sorter: (a, b) => a?.remark4Id?.remark?.localeCompare(b?.remark4Id?.remark),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.remark || ''}
				/>
			),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'billNumber',
			width: '10%',
			render: (value, row) => {
				return (
					<Row
						gutter={10}
						justify="center"
						style={{
							height: 30,
						}}>
						{billItemLoading ? (
							<Spin color="white" />
						) : (
							<Col
								className="edit_icon"
								onClick={() => {
									// getItemsByBillId(row?._id);
									setItems(row?.items || []);
									setSelectedRecordToPrint(row);
									setTimeout(() => {
										handlePrint();
									}, 100);
								}}>
								<Col style={{ padding: 0 }}>
									<PrinterOutlined />
								</Col>
							</Col>
						)}
						<Tooltip placement="left" title={`View items`}>
							<Col
								onClick={() => {
									getItemsByBillIdModal(row?._id, row?.billNumber, row?.items || []);
								}}
								className="edit_icon">
								<EyeOutlined />
							</Col>
						</Tooltip>

						{/* <Popconfirm
							title={`Are you sure to delete this Order: ${value}?`}
							okText="Delete"
							cancelText="No"
							placement="rightTop"
							onConfirm={() => {
								let url = `${SERVER_IP}billing/${row._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
								dispatch(deleteApi('DELETE_BILLING', url));
							}}>
							<Col className="delete_icon">
								<CloseOutlined />
							</Col>
						</Popconfirm> */}
					</Row>
				);
			},
		},
	];

	// eslint-disable-next-line
	// const handleSelectRow = (row) => {
	// 	setSelectedRow(row);
	// };

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: '5%',
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		dates?.startDate && dates?.endDate && getOrders();
	}, [getOrders, dates]);

	useEffect(() => {
		setTableData(billings);
	}, [billings]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	// const getItemsByBillId = useCallback(
	// 	async (id) => {
	// 		await setBillItemLoading(true);
	// 		const res = await sendGetRequest(null, `${SERVER_IP}bill/billitem?orgId=${globalRedux?.selectedOrganization?._id}&billId=${id}`);
	// 		console.log('🚀 ~ file: billings-list-functional.js:294 ~ res:', res);
	// 		setItems(res?.data?.data || []);
	// 		setTimeout(() => {
	// 			handlePrint();
	// 		}, 100);
	// 		await setBillItemLoading(false);
	// 	},
	// 	[setBillItemLoading, globalRedux?.selectedOrganization?._id, handlePrint]
	// );

	const getItemsByBillIdModal = useCallback(
		async (id, billNumber, items) => {
			// const res = await sendGetRequest(
			// 	null,
			// 	`${SERVER_IP}bill/billitem?orgId=${globalRedux?.selectedOrganization?._id}&billId=${id}`
			// );
			handleViewRow({ billNumber: billNumber, items: items || [] });
		},
		[]
	);

	// useEffect(() => {
	// 	selectedRecordToPrint?._id && getItemsByBillId(selectedRecordToPrint?._id);
	// 	console.log('🚀 ~ file: billings-list-functional.js:290 ~ useEffect ~ selectedRecordToPrint:', selectedRecordToPrint?._id);
	// }, [selectedRecordToPrint?._id, getItemsByBillId]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_BILLING === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_BILLING'));
			doIt = true;
		}
		if (doIt) {
			getOrders();
		}
	}, [globalRedux.apiStatus, getOrders, dispatch]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_BILLINGS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<ComponentToPrint
					ref={componentRef}
					data={{
						...selectedRecordToPrint,
						items,
					}}
				/>
			</div>
			<BillingsListPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					refreshList: getOrders,
					tableLoading,
					state,
					setState,
					selectedRecordToPrint: selectedRow,
					setSearchKey,
					rowSelection,
					selectedRowKeys,
					selectedViewRow,
					setSelectedViewRow,
					dates,
					setDates,
				}}
			/>
		</>
	);
});

export default BillingsListFunctional;
