import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDateFormat } from 'services/Utils';
import './item-report-to-print.scss';

const ItemReportToPrint = forwardRef((props, ref) => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const { data } = props?.data || {};
	// const globalRedux = useSelector((state) => state.globalRedux);
	// const { voucherHeads = [] } = globalRedux;
	// const voucherHead = voucherHeads?.find((voucherHead) => voucherHead?._id === voucherHeadId);

	return (
		<div className="invoice-pdf-container" ref={ref}>
			<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1rem' }}>Item Report</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td colSpan="3">
						<table border="1" className="top-heading">
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td colSpan="3" style={{ padding: 0 }}>
									<table>
										<tr>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderLeftWidth: 0,
													textAlign: 'center',
												}}>
												S.No
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Item Name
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Item/Barcode
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												HsnSac
											</td>
											{/* <td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>Rate</td> */}
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													textAlign: 'center',
												}}>
												<div style={{ paddingRight: 10 }}>Description</div>
											</td>
										</tr>
										{(data || [])?.map((data, index) => (
											<tr>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														borderLeftWidth: 0,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.itemNumber}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														padding: 5,
														height: 30,
														textAlign: 'center',
													}}>
													{data?.itemName}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.itemCode}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.hsnSac}
												</td>
												{/* <td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.rate}
												</td> */}
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: 'center',
														height: 30,
													}}>
													{data?.description}
												</td>
											</tr>
										))}
									</table>
								</td>
							</tr>
							<tr style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td style={{ width: '50%' }}>
									<div className="content" style={{}}>
										<div>
											Printed by {loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
									{/* <div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div> */}
								</td>
								<td colSpan={'2'} style={{ width: '50%' }}>
									{/* <div
										className="content"
										style={{
											display: 'flex',
											fontSize: '1rem',
											justifyContent: 'space-between',
										}}>
										<div className="title" style={{ textAlign: 'right' }}>
											Total Credit
										</div>
										<div className="title" style={{ textAlign: 'right' }}>
											{totalCredit}
										</div>
									</div>
									<div
										className="content"
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											fontSize: '1rem',
										}}>
										<div className="title" style={{ textAlign: 'right' }}>
											Total Debit
										</div>
										<div className="title" style={{ textAlign: 'right' }}>
											{totalDebit}
										</div>
									</div> */}
									<div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default ItemReportToPrint;
