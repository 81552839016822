import React, { useEffect } from 'react';
import { Input, Button, Divider, Form, Row, Col } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import DatePicker from 'components/date-picker';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS } from 'constants/app-constants';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';

const { TextArea } = Input;

const AddVoucherHead = ({ handleClose, editVoucher, setVoucherHeadModal, editItem }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addAccBook = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};

		dispatch(postApi(data, 'ADD_VOUCHER_HEAD'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}voucherhead/${editVoucher._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, 'EDIT_VOUCHER_HEAD', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_VOUCHER_HEAD === 'SUCCESS' || globalRedux.apiStatus.EDIT_VOUCHER_HEAD === 'SUCCESS') {
			dispatch(resetApiStatus(editVoucher ? 'EDIT_VOUCHER_HEAD' : 'ADD_VOUCHER_HEAD'));
			setVoucherHeadModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, editVoucher, handleClose, setVoucherHeadModal, dispatch]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_VOUCHER_HEAD === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_VOUCHER_HEAD === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
			<Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editVoucher ? 'Edit' : 'New'} Voucher Head</h6>
					</Col>
				</Row>
				<Divider />
					<Form
						name="add-staff"
						style={{}}
						requiredMark={false}
						colon={false}
						labelAlign="left"
						form={form}
						onFinish={!editVoucher ? addAccBook : handleEdit}
						{...layer1FormCol}>
						{/* <Form.Item
						label="Voucher type"
						name="voucherType"
						initialValue={editVoucher?.voucherType}
						rules={[
							{
								required: true,
								message: 'This Field is Required!',
							},
						]}>
						<Select placeholder="Select">
							<Select.Option value="Income">Income</Select.Option>
							<Select.Option value="Expenses">Expenses</Select.Option>
						</Select>
					</Form.Item> */}
						<Form.Item
							label="Voucher name"
							name="voucherheadName"
							initialValue={editVoucher?.voucherheadName}
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<Input placeholder="Voucher name" />
						</Form.Item>
						<Form.Item label="Opening Balance" name="openingBalance" initialValue={editVoucher?.openingBalance || 0}>
							<Form.Item name="openingBalance">
								<Input type="number" placeholder="Account Balance" />
							</Form.Item>
						</Form.Item>
						<Form.Item
							label="Opening Date"
							name="openingDate"
							initialValue={moment(editVoucher?.openingDate)}
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<DatePicker style={{ width: '100%'}} defaultValue={moment(editVoucher?.openingDate)} format="DD/MM/YYYY" />
						</Form.Item>
						<Form.Item label="Remarks" name="remarks" initialValue={editVoucher?.remarks}>
							<TextArea />
						</Form.Item>
						<Form.Item
							wrapperCol={{
								offset: 0,
								span: 24,
							}}>
							<Row className="space-between">
								<Button loading={loading} type="primary" style={{ marginRight: '10px', width: '49%' }} htmlType="submit">
									Create
								</Button>
								<Button onClick={() => setVoucherHeadModal(false)} style={{ width: '49%' }} danger>
									Cancel
								</Button>
							</Row>
						</Form.Item>
					</Form>
			</Col>
		</Row>
	);
};

export default AddVoucherHead;
