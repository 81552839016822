import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { API_STATUS, DATE_FORMAT, NOTIFICATION_STATUS_TYPES } from 'constants/app-constants';
import { sendGetRequest, sendPostRequest } from 'redux/sagas/utils';
import { formQueryStringFromObject, showToast } from 'helpers';
import { getDateFormat } from 'services/Utils';
import DailyPresentational from './daily-report-list-presentational';
import DailyToPrint from './daily-report-to-print';
// import { getApi } from 'redux/sagas/getApiDataSaga';
// import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const DailyFunctional = React.memo(() => {
	const [form] = Form.useForm();
	const [fetchingData, setFetchingData] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState('');
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	// const dispatch = useDispatch();

	// const getOrders = useCallback(() => {
	// 	dispatch(getApi(ACTIONS.GET_BILLINGS, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}`));
	// }, [dispatch, globalRedux?.selectedOrganization?._id]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.billNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.billingDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.deliveryDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.status || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerId?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const column = [
		{
			title: 'Voucher #',
			dataIndex: 'voucherNumber',
			key: 'voucherNumber',
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Voucher Date',
			dataIndex: 'voucherDate',
			key: 'voucherDate',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value ? moment(value).format(DATE_FORMAT.DD_MM_YYYY) : ''}
				/>
			),
		},
		{
			title: 'Voucher Head Name',
			dataIndex: 'voucherheadId',
			key: 'voucherheadId',
			fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.voucherheadName}
				/>
			),
		},
		{
			title: 'Transaction Type',
			dataIndex: 'transactionType',
			key: 'transactionType',
			fixed: 'left',
			width: 150,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			align: 'left',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			render: (value) => value?.firstName,
		},
	];

	// eslint-disable-next-line
	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: '5%',
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	// useEffect(() => {
	// 	let doIt = false;
	// 	if (globalRedux.apiStatus.DELETE_BILLING === 'SUCCESS') {
	// 		dispatch(resetApiStatus('DELETE_BILLING'));
	// 		doIt = true;
	// 	}
	// 	if (doIt) {
	// 		getOrders();
	// 	}
	// }, [globalRedux.apiStatus, getOrders, dispatch]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const getBillingItems = useCallback(
		async (values) => {
			try {
				const string = formQueryStringFromObject({
					specificDate: (values?.specificDate).format(DATE_FORMAT.YYYY_MM_DD),
				});
				await setFetchingData(true);
				const res = await sendGetRequest(null, `${SERVER_IP}report?orgId=${globalRedux?.selectedOrganization?._id}&${string}`);
				// await setTableData(res?.data?.data || []);
				const response = res?.data;
				await setFetchingData(false);
				if (response) {
					// const labels = ['Cash Bills', 'Credit Bills', 'Credit Collection', 'Credit Vouchers', 'Debit Vouchers'];
					const combinedArray = [
						...response?.cashBills,
						...response?.creditBills,
						...response?.creditCollection,
						...response?.creditVouchers,
						...response?.debitVouchers,
					];
					const listData = combinedArray.map((item, index) => ({
						refNumber: item.refNumber,
						refDate: item.refDate,
						particulars: item.particulars,
						amount: item.Amount,
						label: item?.type
					}));
					const totalCashBills = response?.cashBills?.reduce((acc, prev) => acc + prev?.Amount, 0);
					const totalCreditBills = response?.creditBills?.reduce((acc, prev) => acc + prev?.Amount, 0);
					const totalCreditCollection = response?.creditCollection?.reduce((acc, prev) => acc + prev?.Amount, 0);
					const totalCreditVouchers = response?.creditVouchers?.reduce((acc, prev) => acc + prev?.Amount, 0);
					const totalDebitVouchers = response?.debitVouchers?.reduce((acc, prev) => acc + prev?.Amount, 0);
					console.log('🚀 ~ file: daily-report-list-functional.js:242 ~ formattedData:', listData);
					if (listData?.length > 0) {
						setSelectedRecordToPrint({
							...form.getFieldsValue(),
							data: listData,
							totalCashBills,
							totalCreditBills,
							totalCreditCollection,
							totalCreditVouchers,
							totalDebitVouchers,
						});
					} else {
						showToast('Sorry!', 'No data found', NOTIFICATION_STATUS_TYPES.ERROR, 'top-center');
					}
				}
			} catch (error) {
				console.log('🚀 ~ file: daily-report-list-functional.js:242 ~ error:', error);
				await setFetchingData(false);
			}
		},
		[setFetchingData, globalRedux?.selectedOrganization?._id, form]
	);

	const updateStatusItems = useCallback(
		async (values) => {
			console.log('🚀 ~ file: unit-sections-list-functional.js:255 ~ values:', values);
			await setUpdatingData(true);
			const res = await sendPostRequest({
				url: `${SERVER_IP}bill/status`,
				body: {
					countryCode: '+91',
				},
			});
			await setTableData(res?.data?.data || []);
			await setUpdatingData(false);
		},
		[setUpdatingData]
	);

	const handleClear = () => {
		setTableData([]);
		form.resetFields();
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_BILLINGS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<DailyToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<DailyPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					// refreshList: getOrders,
					tableLoading,
					state,
					setState,
					editData: selectedRow,
					setSearchKey,
					rowSelection,
					selectedRowKeys,
					selectedViewRow,
					setSelectedViewRow,
					getBillingItems,
					form,
					fetchingData,
					handleClear,
					updateStatusItems,
					updatingData,
					setUpdatingData,
					setSelectedRecordToPrint,
				}}
			/>
		</>
	);
});

export default DailyFunctional;
