import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDateFormat } from 'services/Utils';
import { DATE_FORMAT } from 'constants/app-constants';
import './daily-report-to-print.scss';

const DailyToPrint = forwardRef((props, ref) => {
	// const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	// const { voucherHeads = [] } = globalRedux;
	const { data, totalCashBills, totalCreditBills, totalCreditCollection, totalCreditVouchers, totalDebitVouchers } = props?.data || {};
	console.log(
		'🚀 ~ file: daily-report-to-print.js:13 ~ DailyToPrint ~ totalCashBills, totalCreditBills, totalCreditCollection, totalCreditVouchers, totalDebitVouchers:',
		props?.data
	);

	return (
		<div className="invoice-pdf-container" ref={ref}>
			<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '0.8rem' }}>Daily Report - {moment().format('DD/MM/YYYY')}</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td colSpan="3">
						<table border="1" className="top-heading">
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td colSpan="3" style={{ padding: 0, borderBottomWidth: 0 }}>
									<table>
										<tr>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderLeftWidth: 0,
													textAlign: 'center',
												}}>
												S.No
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderLeftWidth: 0,
													textAlign: 'center',
												}}>
												Label
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderLeftWidth: 0,
													textAlign: 'center',
												}}>
												Ref No
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Ref Date
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Particulars
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 5 }}>Amount</div>
											</td>
										</tr>
										{(data || [])?.map((data, index, arr) => (
											<>
												<tr>
													<td
														rowSpan={data?.items?.length}
														style={{
															borderTopWidth: 0,
															// borderBottomWidth: 1,
															// borderBottomWidth: index + 1 === 10 ? 0 : 1,
															borderLeftWidth: 0,
															textAlign: 'center',
															height: 30,
														}}>
														{index + 1}
													</td>

													<td
														rowSpan={data?.items?.length}
														style={{
															borderTopWidth: 0,
															// borderBottomWidth: 1,
															// borderBottomWidth: index + 1 === 10 ? 0 : 1,
															borderLeftWidth: 0,
															textAlign: 'center',
															height: 30,
														}}>
														{data?.label}
													</td>
													
													<td
														rowSpan={data?.items?.length}
														style={{
															borderTopWidth: 0,
															// borderBottomWidth: 1,
															// borderBottomWidth: index + 1 === 10 ? 0 : 1,
															borderLeftWidth: 0,
															textAlign: 'center',
															height: 30,
														}}>
														{data?.refNumber}
													</td>

													<td
														rowSpan={data?.items?.length}
														style={{
															borderTopWidth: 0,
															// borderBottomWidth: 1,
															// borderBottomWidth: index + 1 === 10 ? 0 : 1,
															textAlign: 'center',
															height: 30,
														}}>
														{data?.refDate ? moment(data?.refDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
													</td>

													<td
														rowSpan={data?.items?.length}
														style={{
															borderTopWidth: 0,
															// borderBottomWidth: 1,
															// borderBottomWidth: index + 1 === 10 ? 0 : 1,
															textAlign: 'center',
															height: 30,
														}}>
														{data?.particulars}
													</td>

													<td
														style={{
															borderTopWidth: 0,
															// borderBottomWidth: 1,
															borderRightWidth: 0,
															textAlign: 'right',
															height: 30,
														}}>
														<div style={{ paddingRight: 5 }}>{parseFloat(data?.amount).toFixed(2)}</div>
													</td>
												</tr>
											</>
										))}
									</table>
								</td>
							</tr>
							<tr
								style={{
									borderTopWidth: 0,
								}}>
								<td
									colSpan="2"
									style={{
										borderTopWidth: 0,
									}}>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Cash Bill:{' '}
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalCashBills)?.toFixed(2)}
											</span>
										</div>
									</div>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Credit Bill:{' '}
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalCreditBills)?.toFixed(2)}
											</span>
										</div>
									</div>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Credit Collection:{' '}
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalCreditCollection)?.toFixed(2)}
											</span>
										</div>
									</div>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Other Income:{' '}
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalCreditVouchers)?.toFixed(2)}
											</span>
										</div>
									</div>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Expenses:{' '}
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalDebitVouchers)?.toFixed(2)}
											</span>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Income:
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalCashBills + totalCreditCollection + totalCreditVouchers)?.toFixed(2)}
											</span>
										</div>
									</div>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Total Expenses:{' '}
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat(totalDebitVouchers)?.toFixed(2)}
											</span>
										</div>
									</div>
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '0.8rem' }}>
											Cash Balance:
											<span style={{ marginLeft: 10, width: 60, display: 'inline-flex', justifyContent: 'flex-end' }}>
												{parseFloat((totalCashBills + totalCreditCollection + totalCreditVouchers) - totalDebitVouchers)?.toFixed(2)}
											</span>
										</div>
									</div>
								</td>
							</tr>
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td style={{ width: '50%' }}>
									<div className="content" style={{}}>
										<div style={{ textAlign: 'left' }}>
											Printed by {loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
								</td>
								<td style={{ width: '50%' }}>
									<div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default DailyToPrint;
