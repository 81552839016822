import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useReactToPrint } from 'react-to-print';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Form, Select, Input, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
// import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { putApi } from 'redux/sagas/putApiSaga';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { getTheRoundOffValue } from 'helpers';
import AddCustomer from 'pages/customers/add-customer';
import { sendGetRequest, sendPostRequest } from 'redux/sagas/utils';
import AddItem from 'pages/items/add-item';
import AddBillingPresentational from './add-billing-presentational';
import BillingToPrint from './billing-component-to-print';

const MySwal = withReactContent(Swal);

const { Option } = Select;

let itemDefaultRecord = {
	itemId: null,
	remark1Id: null,
	remark2Id: null,
	hsnSac: null,
	rate: null,
	qty: null,
	id: uuidv4(),
};

export const calculatePurchaseValues = (data) => {
	// const discountAmount =
	// 	(data?.discount || 0) > 0 && (data?.discount || 0) !== ''
	// 		? ((data?.rate || 0) * data?.qty || 0) * ((data?.discount || 0) / 100)
	// 		: data?.discountAmount || 0;
	// const actualTotal = (data?.qty || 0) * (data?.rate || 0);
	// const taxableValue = parseFloat(actualTotal - discountAmount).toFixed(2);
	// const taxAmount = parseFloat((actualTotal - discountAmount) * (data?.taxRate / 100) || 0).toFixed(2);
	return {
		// actualTotal,
		// discountAmount: discountAmount,
		// taxableValue,
		// taxAmount,
		totalAmount: +data?.qty * +data?.rate,
	};
};

const AddBillingFunctional = ({ state, setState, refreshList, editData }) => {
	const [form] = Form.useForm();
	const categoryIdValue = Form.useWatch('categoryId', form);
	const [isPaid, setIsPaid] = useState(false);
	const [billItemLoading, setBillItemLoading] = useState(false);
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [discount, setDiscount] = useState('');
	const [remark3Id, setRemark3Id] = useState('');
	const [remark4Id, setRemark4Id] = useState('');
	const [selectedData, setSelectedData] = useState(null);
	const [addingBilling, setAddingBilling] = useState(false);
	const [tableData, setTableData] = useState([{ ...itemDefaultRecord, id: uuidv4() }]);
	const [customerAddModal, setCustomerAddModal] = useState(false);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const globalRedux = useSelector((state) => state.globalRedux);
	const washRemarks = useMemo(() => globalRedux.washRemarks || [], [globalRedux.washRemarks]);
	const washTypes = useMemo(() => globalRedux.washTypes || [], [globalRedux.washTypes]);
	const itemRates = useMemo(() => globalRedux.itemRates || [], [globalRedux.itemRates]);
	const items = useSelector((state) => state.itemRedux?.items || []);
	const customers = useSelector((state) => state.customerRedux?.customers || []);
	const componentRef = React.useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
	}, [categoryIdValue]);

	// const groupItemRateData = useMemo(
	// 	() =>
	// 		_.chain(washRemarks)
	// 			// Group the elements of Array based on `tax` property
	// 			.groupBy('field')
	// 			// `key` is group's name (tax), `value` is the array of objects
	// 			.map((value, key) => ({
	// 				taxRate: key,
	// 				data: value,
	// 			}))
	// 			.value(),
	// 	[washRemarks]
	// );

	useEffect(() => {
		const categoryValue = (washTypes?.find((washType) => washType?._id === categoryIdValue)?.categoryName || '').toLowerCase();
		if (categoryValue === 'urgent') {
			const fields = form.getFieldsValue();
			form.setFieldsValue({
				deliveryDate: moment(fields?.billDate).add('days', 1),
			});
		} else {
			form.setFieldsValue({
				deliveryDate: moment().add('days', 5),
			});
		}
	}, [categoryIdValue, form, washTypes]);

	const remark1Data = washRemarks?.filter((washRemark) => washRemark?.field === 'starch');
	const remark2Data = washRemarks?.filter((washRemark) => washRemark?.field === 'shades');
	const remark3Data = washRemarks?.filter((washRemark) => washRemark?.field === 'stain');
	const remark4Data = washRemarks?.filter((washRemark) => washRemark?.field === 'damage');
	// console.log('🚀 ~ file: add-billing-functional.js:62 ~ AddBillingFunctional ~ itemRates:', remark1Data, remark2Data, remark3Data);

	const getItemRates = useCallback(() => {
		let url = `${SERVER_IP}rate/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_ITEM_RATES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const showPropsConfirm = () => {
		MySwal.fire({
			title: 'Payment Received?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#25b394',
			denyButtonColor: '#6e7881',
			confirmButtonText: 'No',
			denyButtonText: 'Cancel',
			cancelButtonText: 'Yes',
			customClass: {
				actions: 'my-actions',
				cancelButton: 'order-1 right-gap',
				confirmButton: 'order-2',
				denyButton: 'order-3',
			},
		}).then((result) => {
			console.log('🚀 ~ file: add-billing-functional.js:134 ~ showPropsConfirm ~ result:', result);
			if (result.isDenied) {
				// setState({ ...state, visible: false });
			} else if (result?.isDismissed) {
				setIsPaid(true);
				setTimeout(() => {
					form.submit();
				}, 100);
			} else {
				form.submit();
			}
			// if (result.isConfirmed) {
			// 	setIsPaid(true);
			// 	setTimeout(() => {
			// 		form.submit();
			// 	}, 100);
			// } else if (result?.isDenied) {
			// 	setState({ ...state, visible: false });
			// } else {
			// 	form.submit();
			// }
		});
	};

	const getWashRemarks = useCallback(() => {
		let url = `${SERVER_IP}remark/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_WASH_REMARKS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getwashTypes = useCallback(() => {
		let url = `${SERVER_IP}category?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_WASH_TYPES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		if (state?.visible) {
			getWashRemarks();
			getwashTypes();
		}
	}, [getWashRemarks, getwashTypes, state?.visible]);

	const generateTableData = useCallback(
		(editData) => {
			if (editData._id) {
				form.setFieldsValue({
					customerId: editData?.customerId?._id,
					billDate: moment(editData?.billDate),
					deliveryDate: moment(editData?.deliveryDate),
					totalAmount: editData?.totalAmount,
					remarks: editData?.remarks,
				});
				setDiscount(editData?.discount);
				if (editData?.items?.length > 0) {
					const data = editData.items.map((item) => ({
						itemId: item?.itemId?._id || '',
						hsnSac: item?.hsnSac || '',
						qty: item?.qty || '',
						rate: item?.rate || '',
						totalAmount: item?.totalAmount || '',
						...calculatePurchaseValues(item),
						id: uuidv4(),
					}));
					setTableData(data);
				}
			}
		},
		[setTableData, form]
	);

	const getItems = useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_ITEMS', url));
	}, [dispatch, globalRedux.selectedOrganization._id]);

	useEffect(() => {
		getItems();
		getItemRates();
	}, [getItems, getItemRates]);

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_CUSTOMERS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	const getItemsByBillId = useCallback(
		async (editData) => {
			await setBillItemLoading(true);
			const res = await sendGetRequest(
				null,
				`${SERVER_IP}bill/billitem?orgId=${globalRedux?.selectedOrganization?._id}&billId=${editData?._id}`
			);
			await generateTableData({
				...editData,
				items: res?.data?.data || [],
			});
			await setBillItemLoading(false);
		},
		[setBillItemLoading, generateTableData, globalRedux?.selectedOrganization?._id]
	);

	useEffect(() => {
		if (!state?.visible) {
			form.resetFields();
			setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
			setDiscount('');
			setRemark3Id('');
			setRemark4Id('');
		}
	}, [state?.visible, form]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_BILLING === API_STATUS.SUCCESS || globalRedux.apiStatus.EDIT_BILLING === API_STATUS.SUCCESS) {
			setState((state) => ({ ...state, visible: false }));
			refreshList();
			form.resetFields();
			setIsPaid(false);
			dispatch(resetApiStatus(editData ? 'EDIT_BILLING' : 'ADD_BILLING'));
		}
		if (globalRedux.apiStatus.ADD_BILLING === API_STATUS.FAILED || globalRedux.apiStatus.EDIT_BILLING === API_STATUS.FAILED) {
			setIsPaid(false);
		}
		!editData && setTableData([{ ...itemDefaultRecord, id: uuidv4() }]);
	}, [globalRedux.apiStatus, editData, refreshList, dispatch, setState, form]);

	useEffect(() => {
		if (editData) {
			// generateTableData(editData);
			getItemsByBillId(editData);
		}
	}, [editData, getItemsByBillId]);

	const loading =
		globalRedux.apiStatus.ADD_BILLING === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_BILLING === API_STATUS.PENDING || addingBilling;
	const subTotal = useMemo(() => tableData.reduce((accum, item) => accum + (item?.totalAmount || 0), 0) || 0, [tableData]);
	const totalQty = useMemo(() => tableData.reduce((accum, item) => accum + (item?.qty || 0), 0) || 0, [tableData]);
	// const totalAmount = useMemo(() => tableData.reduce((accum, item) => +accum + +item.totalAmount, 0) || 0, [tableData]);
	const roundOff = getTheRoundOffValue(subTotal - discount);

	const handleSubmit = async (values) => {
		const request = {
			orgId: globalRedux?.selectedOrganization?._id,
			...values,
			subTotal,
			totalQty,
			discount: discount || 0,
			remark3Id: remark3Id || '',
			remark4Id: remark4Id || '',
			...(isPaid && {
				paymentStatus: 'Paid',
				paymentReceivedDate: moment(),
			}),
			deliveryDate: moment(values?.deliveryDate).format(DATE_FORMAT.YYYY_MM_DD),
			// roundOff: roundOff?.remain || 0,
			totalAmount: parseFloat(roundOff.value).toFixed(2),
			items: tableData
				.filter((data) => data.itemId)
				.map(({ itemId, remark1Id, remark2Id, hsnSac, rate, qty, totalAmount }) => ({
					itemId,
					hsnSac,
					qty,
					rate: rate || 0,
					totalAmount,
					remark1Id,
					remark2Id,
				})),
		};
		if (editData) {
			dispatch(putApi(request, 'EDIT_BILLING', `${SERVER_IP}bill/${editData?._id}`));
		} else {
			await setAddingBilling(true);
			// dispatch(postApi(request, 'ADD_BILLING'))
			const res = await sendPostRequest({
				url: `${SERVER_IP}bill`,
				body: request,
			});
			if (res?.data) {
				setState((state) => ({ ...state, visible: false }));
				refreshList();
				form.resetFields();
				setIsPaid(false);
				setRemark4Id('');
				if (res?.data?.data?.data?.[0]?.items) {
					setSelectedRecordToPrint({
						...res?.data?.data?.data?.[0],
						items: res?.data?.data?.data?.[0]?.items || [],
					});
					handlePrint();
				}
			}
			await setAddingBilling(false);
		}

		// setSelectedRecordToPrint(row);
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	// const handleSearch = async (searchString) => {
	// 	setSearchList({
	// 		...searchList,
	// 		searchString,
	// 	});
	// 	if (searchString) {
	// 		setSearchList({
	// 			...searchList,
	// 			loading: true,
	// 		});
	// 		const { data } = await sendGetRequest(
	// 			null,
	// 			`${SERVER_IP}student/search?orgId=${globalRedux?.selectedOrganization?._id}&searchText=${searchString}`
	// 		);
	// 		setSearchList({
	// 			...searchList,
	// 			data,
	// 			loading: false,
	// 		});
	// 	}
	// };

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			let item = null;
			let qty = 0;
			// const itemRate = itemRates?.find((item) => item?.categoryId?._id === categoryIdValue && item?.itemId?._id === value)?.rate || 0;
			// item.rate = itemRate;
			if (label === 'itemId') {
				item = items.find((item) => item._id === value);
				qty = 1;
			}
			const data = tableData.map((data) => {
				if (data.id === rowId) {
					// const taxAmount = item?.sellingPrice * qty * (item?.taxRate / 100) || 0;
					let updatedObj = data;
					const remark1Rate =
						remark1Data?.find((washRemark) => washRemark?._id === (label === 'remark1Id' ? value : data?.remark1Id))?.additionalRate || 0;
					const remark2Rate =
						remark2Data?.find((washRemark) => washRemark?._id === (label === 'remark2Id' ? value : data?.remark2Id))?.additionalRate || 0;
					const itemRate =
						itemRates?.find(
							(item) => item?.categoryId?._id === categoryIdValue && item?.itemId?._id === (label === 'itemId' ? value : data?.itemId)
						)?.rate || 0;
					if (item) {
						console.log('🚀 ~ file: add-billing-functional.js:393 ~ data ~ itemRate:', itemRates, value, categoryIdValue);
						item.rate = itemRate + remark1Rate + remark2Rate;
					}
					// console.log(
					// 	'🚀 ~ file: add-billing-functional.js:316 ~ data ~ itemRate + remark1Rate + remark2Rate:',
					// 	itemRate,
					// 	remark1Rate,
					// 	remark2Rate
					// );
					if (data) {
						data.rate = itemRate + remark1Rate + remark2Rate;
					}
					updatedObj[label] = value;
					if (item) {
						updatedObj['hsnSac'] = item?.hsnSac;
						updatedObj['rate'] = item?.rate;
						updatedObj['qty'] = qty;
						updatedObj['totalAmount'] = item?.rate * qty;
					}
					// if (label === 'remark1Id') {
					// 	const additionalRate = remark1Data?.find((washRemark) => washRemark?._id === value)?.additionalRate || 0;
					// 	updatedObj['rate'] = data.rate + additionalRate;
					// }
					// if (label === 'remark2Id') {
					// 	const additionalRate = remark2Data?.find((washRemark) => washRemark?._id === value)?.additionalRate || 0;
					// 	updatedObj['rate'] = data.rate + additionalRate;
					// }
					updatedObj = {
						...updatedObj,
						...calculatePurchaseValues(updatedObj),
					};
					return updatedObj;
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData, items, itemRates, categoryIdValue, remark1Data, remark2Data]
	);

	const groupData = useMemo(
		() =>
			_.chain(tableData?.filter((data) => data?.itemId))
				// Group the elements of Array based on `tax` property
				.groupBy('taxRate')
				// `key` is group's name (tax), `value` is the array of objects
				.map((value, key) => ({
					taxRate: key,
					data: value,
					taxAmount: value.reduce(function (acc, obj) {
						return +acc + +obj?.taxAmount;
					}, '0.00'),
					hsnSac: value[0]?.hsnSac,
					cgstPer: parseInt(key) / 2,
					cgstValue: parseFloat(
						value.reduce(function (acc, obj) {
							return +acc + +obj?.taxAmount;
						}, '0.00') / 2
					).toFixed(2),
					sgstPer: parseInt(key) / 2,
					sgstValue: parseFloat(
						value.reduce(function (acc, obj) {
							return +acc + +obj?.taxAmount;
						}, '0.00') / 2
					).toFixed(2),
					igstPer: '',
					igstValue: 0,
				}))
				.value(),
		[tableData]
	);

	const columns = [
		{
			title: '',
			dataIndex: 'item',
			key: 'item',
			align: 'center',
			width: '5%',
			render: (value, record) =>
				tableData.length > 1 && record?.itemId ? <DeleteOutlined style={{ color: 'red' }} onClick={() => handleRemove(record.id)} /> : null,
		},
		{
			title: 'S No',
			dataIndex: 'sno',
			key: 'sno',
			width: '3%',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Item',
			dataIndex: 'itemId',
			key: 'itemId',
			width: '20%',
			align: 'left',
			render: (value, record) => (
				<Select
					showSearch
					disabled={!!!categoryIdValue}
					optionFilterProp="children"
					filterOption={(input, option) =>
						option.props.id.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
						option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
					value={value}
					style={{ width: '100%', textAlign: 'left' }}
					placeholder="Select Item"
					onChange={(value) => handleInputChange('itemId', value, record?.id)}
					// dropdownRender={(menu) => (
					// 	<div>
					// 		{menu}
					// 		<Divider />
					// 		<div style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }} onClick={() => setShowAddItemModal(true)}>
					// 			<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
					// 				<PlusOutlined /> Item
					// 			</a>
					// 		</div>
					// 	</div>
					// )}
				>
					{items?.map((item) => (
						<Option key={item?._id} value={item?._id} id={item?.itemName}>
							<section>
								<Col>{`${item?.itemName}`}</Col>
								{/* <Col style={{ fontWeight: 'bold' }}>{convertToIndianRupees(item?.rate)}</Col> */}
							</section>
						</Option>
					))}
				</Select>
			),
		},
		{
			title: 'Starch',
			dataIndex: 'remark1Id',
			key: 'remark1Id',
			width: '10%',
			align: 'left',
			render: (value, record) => (
				<Select
					disabled={!!!categoryIdValue}
					value={value}
					className={`${record?.itemId && !value ? 'error' : ''}`}
					style={{ width: '100%', textAlign: 'left' }}
					placeholder="Select Starch"
					onChange={(value) => handleInputChange('remark1Id', value, record?.id)}>
					{remark1Data?.map((remark1) => (
						<Option key={remark1?._id} value={remark1?._id} id={remark1?._id}>
							{remark1?.remark}
						</Option>
					))}
				</Select>
			),
		},
		{
			title: 'Shades',
			dataIndex: 'remark2Id',
			key: 'remark2Id',
			width: '10%',
			align: 'left',
			render: (value, record) => (
				<Select
					disabled={!!!categoryIdValue}
					value={value}
					className={`${record?.itemId && !value ? 'error' : ''}`}
					style={{ width: '100%', textAlign: 'left' }}
					placeholder="Select Shades"
					onChange={(value) => handleInputChange('remark2Id', value, record?.id)}>
					{remark2Data?.map((remark1) => (
						<Option key={remark1?._id} value={remark1?._id} id={remark1?._id}>
							{remark1?.remark}
						</Option>
					))}
				</Select>
			),
		},
		{
			title: 'Quantity',
			dataIndex: 'qty',
			key: 'qty',
			width: '5%',
			align: 'right',
			render: (value, record) => (
				<Input
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					value={record?.qty}
					placeholder="qty"
					disabled={!record?.itemId}
					style={{ textAlign: 'right', width: '100%' }}
					className={`${record?.itemId && !value ? 'error' : ''}`}
					onChange={({ target: { value } }) => handleInputChange('qty', parseFloat(value), record?.id)}
				/>
			),
		},
		// {
		// 	title: 'Rate',
		// 	dataIndex: 'rate',
		// 	key: 'rate',
		// 	width: '7%',
		// 	align: 'right',
		// 	render: (value, record) => (
		// 		<Input
		// 			type="number"
		// 			pattern="^-?[0-9]\d*\.?\d*$"
		// 			value={value}
		// 			placeholder="rate"
		// 			disabled={!record?.itemId}
		// 			style={{ textAlign: 'right' }}
		// 			className={`${record?.itemId && !value ? 'error' : ''}`}
		// 			onChange={({ target: { value } }) => handleInputChange('rate', parseFloat(value), record?.id)}
		// 		/>
		// 	),
		// },

		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			width: '7%',
			align: 'right',
			render: (value, record) => parseFloat(value || 0).toFixed(2),
		},
		// {
		// 	title: 'Serials',
		// 	dataIndex: 'serials',
		// 	key: 'serials',
		// 	width: '10%',
		// 	align: 'center',
		// 	render: (value, record) => {
		// 		console.log('🚀 ~ file: add-inventory-functional.js:313 ~ AddBillingFunctional ~ record:', record);
		// 		return (
		// 			<Tooltip title={(record?.serials || [])?.map((serial) => serial?.serial).join(',')}>
		// 				<Button onClick={() => setSelectedData(record)} disabled={!record?.itemId} type="ghost">
		// 					{record?.serials?.length || 0} Serials
		// 				</Button>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		// {
		// 	title: 'Tax Value',
		// 	dataIndex: 'taxAmount',
		// 	key: 'taxAmount',
		// 	width: '5%',
		// 	align: 'right',
		// 	render: (value, record) => <span>{parseFloat(value || 0).toFixed(2)}</span>,
		// },
		{
			title: 'Total',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '7%',
			align: 'right',
			render: (value, record) => parseFloat(value || 0).toFixed(2),
		},
	];

	const groupByColumns = [
		{
			title: 'S No',
			dataIndex: 'sno',
			key: 'sno',
			width: '10%',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'HSNSAC',
			dataIndex: 'hsnSac',
			key: 'hsnSac',
			render: (value, record) => <span>{value}</span>,
		},
		{
			title: 'cgst%',
			dataIndex: 'cgstPer',
			key: 'cgstPer',
			align: 'right',
			render: (value, record) => <span>{value || 0}%</span>,
		},
		{
			title: 'cgst',
			dataIndex: 'cgstValue',
			key: 'cgstValue',
			align: 'right',
			render: (value, record) => <span>{value || 0}</span>,
		},
		{
			title: 'sgst%',
			dataIndex: 'sgstPer',
			key: 'sgstPer',
			align: 'right',
			render: (value, record) => <span>{value || 0}%</span>,
		},
		{
			title: 'sgst',
			dataIndex: 'sgstValue',
			key: 'sgstValue',
			align: 'right',
			render: (value, record) => <span>{value || 0}</span>,
		},
		{
			title: 'igst%',
			dataIndex: 'igstPer',
			key: 'igstPer',
			align: 'right',
			render: (value, record) => <span>{value || 0}%</span>,
		},
		{
			title: 'igst',
			dataIndex: 'igstValue',
			key: 'igstValue',
			align: 'right',
			render: (value, record) => <span>{value || 0}</span>,
		},
	];

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
	};

	const handleAddTableData = useCallback(() => {
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			id: uuidv4(),
		});
		setTableData(data);
	}, [tableData]);

	const { isValid } = useMemo(() => {
		// const selectedList = tableData?.map((data) => data.accBookId).filter((data) => data);
		const filledList = tableData?.map((data) => data.itemId && data.remark1Id && data.remark2Id).filter((data) => data);
		if (tableData?.length === filledList?.length) {
			handleAddTableData();
		}
		return {
			isValid: filledList?.length > 0,
		};
	}, [tableData, handleAddTableData]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<BillingToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<AddBillingPresentational
				{...{
					state,
					setState,
					handleSubmit,
					columns,
					loading,
					editData,
					form,
					customers,
					isValid,
					roundOff,
					setCustomerAddModal,
					handleInputChange,
					selectedData,
					setSelectedData,
					tableData,
					setTableData,
					groupByColumns,
					groupData,
					discount,
					setDiscount,
					subTotal,
					totalQty,
					billItemLoading,
					showPropsConfirm,
					remark3Data,
					remark4Data,
					remark3Id,
					setRemark3Id,
					remark4Id,
					setRemark4Id,
					washTypes,
				}}
			/>
			<AddItem {...{ showAddItemModal, setShowAddItemModal }} />
			<AddCustomer {...{ customerAddModal, setCustomerAddModal, refreshList: getCustomers, handleClose: () => setCustomerAddModal(false) }} />
		</>
	);
};

export default AddBillingFunctional;
