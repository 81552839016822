import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';

const AddVendor = ({ handleClose, editVendor, setVendorAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	// const states = useSelector((state) => state.globalRedux?.states);

	const addVendor = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, 'ADD_VENDOR'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}vendor/${editVendor._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, 'EDIT_VENDOR', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_VENDOR === 'SUCCESS' || globalRedux.apiStatus.EDIT_VENDOR === 'SUCCESS') {
			dispatch(resetApiStatus(editVendor ? 'EDIT_VENDOR' : 'ADD_VENDOR'));
			setVendorAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, editVendor, handleClose, setVendorAddModal, dispatch]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_VENDOR === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_VENDOR === API_STATUS.PENDING;

	return (
		<Container fluid>
			<Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editVendor ? 'Edit' : 'New'} Vendor</h6>
				</Col>
			</Row>
			<Divider />
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-vendor"
					colon={false}
					labelAlign="left"
					className="required_in_right"
					form={form}
					onFinish={!editVendor ? addVendor : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Vendor Name"
						name="vendorName"
						initialValue={editVendor?.vendorName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Vendor Type"
						name="vendorType"
						initialValue={editVendor?.vendorType}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select placeholder="select vendor type">
							<Select.Option value={'Supplier'}>Supplier</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Place of Supply"
						name="placeOfSupply"
						initialValue={editVendor?.placeOfSupply}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select placeholder="Place of Supply">
							{/* {states?.map((data, index) => (
								<Select.Option key={data?.stateCode} value={data?.stateCode}>
									{data?.stateName}
								</Select.Option>
							))} */}
							<Select.Option value={'Tamilnadu'}>Tamilnadu</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Contact"
						name="mobile"
						initialValue={editVendor?.mobile}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item label="Email" name="email" initialValue={editVendor?.email}>
						<Input />
					</Form.Item>

					<Form.Item label="GSTIN" name="gstin" initialValue={editVendor?.gstin}>
						<Input />
					</Form.Item>
					<Form.Item label="Opening Balance" name="openingBalance" initialValue={editVendor?.openingBalance}>
						<InputNumber style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item label="Billing Address" name="billingAddress" initialValue={editVendor?.billingAddress}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item label="Shipping Address" name="shippingAddress" initialValue={editVendor?.shippingAddress}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item label="Remarks" name="remarks" initialValue={editVendor?.remarks}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setVendorAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editVendor ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddVendor;
