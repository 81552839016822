import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts';

// const SAMPLE_DATA = [
// 	{
// 		totalAmount: 1486,
// 		date: '2023-11-08',
// 	},
// 	{
// 		totalAmount: 875,
// 		date: '2023-11-09',
// 	},
// 	{
// 		totalAmount: 591,
// 		date: '2023-11-13',
// 	},
// 	{
// 		totalAmount: 200,
// 		date: '2023-11-17',
// 	},
// ];

export const RechartsLineChart = ({ data }) => {
	const [dailyRecorcd, setDailyRecords] = useState([]);

	useEffect(() => {
		const dailyRecorcd = data.map(({ totalAmount, date }) => ({
			name: moment(date).format('DD MMM'), // Assuming the "date" property corresponds to the "name" property in the original data
			uv: totalAmount, // Assuming "totalAmount" corresponds to the "uv" property in the original data
			Amount: totalAmount, // You can set this to any default value or calculate it based on your requirements
			amt: totalAmount, // You can set this to any default value or calculate it based on your requirements
		}));
		console.log('🚀 ~ file: recharts-line-chart.js:23 ~ dailyRecorcd ~ dailyRecorcd:', dailyRecorcd);
		setDailyRecords(dailyRecorcd);
	}, [data]);

	return (
		<div style={{ width: '100%' }}>
			<ResponsiveContainer width="100%" height={300}>
				<AreaChart
					width={500}
					// height={200}
					data={dailyRecorcd}
					syncId="anyId"
					margin={{
						top: 10,
						right: 30,
						left: 0,
						bottom: 0,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Area type="monotone" dataKey="Amount" stroke="#35bd7c" fill="#35bd7c" />
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};
