let initialState = {
	billings: [],
};

const billingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_BILLINGS':
			return {
				...state,
				billings: action.payload,
			};
		default:
			return state;
	}
};

export { billingsReducer };
