import React, { useCallback, useEffect } from 'react';
import { Input, Button, Form, Row, Col, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { convertToIndianRupees } from 'helpers';
import WashTypeModal from 'components/wash-type-modal';

const { Option } = Select;

// let contactPersonData = {
// 	id: null,
// 	salutation: null,
// 	firstName: null,
// 	lastName: null,
// 	email: null,
// 	designation: null,
// 	mobile: null,
// };

const AddItemRate = ({ handleClose, editItemRate, setItemRateAddModal }) => {
	console.log("🚀 ~ file: add-item-rate.js:26 ~ AddItemRate ~ editItemRate:", editItemRate)
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const items = useSelector((state) => state.itemRedux?.items || []);

	const getItems = useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_ITEMS', url));
	}, [dispatch, globalRedux.selectedOrganization._id]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	const addAccBook = (values) => {
		let data = {
			...values,

			displayName: values.displayName,
			email: values.email,
			mobile: values.mobile,
			orgId: globalRedux?.selectedOrganization?._id,
		};

		dispatch(postApi(data, 'ADD_ITEM_RATE'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}rate/${editItemRate._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, 'EDIT_ITEM_RATE', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_ITEM_RATE === 'SUCCESS' || globalRedux.apiStatus.EDIT_ITEM_RATE === 'SUCCESS') {
			dispatch(resetApiStatus(editItemRate ? 'EDIT_ITEM_RATE' : 'ADD_ITEM_RATE'));
			setItemRateAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, editItemRate, setItemRateAddModal, handleClose, dispatch]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_ITEM_RATE === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_ITEM_RATE === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Form
							name="add-staff"
							label="Item"
							requiredMark={false}
							labelAlign="left"
							form={form}
							onFinish={!editItemRate ? addAccBook : handleEdit}
							{...layer1FormCol}>
							<Form.Item
								label="Item"
								name="itemId"
								initialValue={editItemRate?.itemId?._id}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.id.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
										option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									placeholder="Select item"
									// dropdownRender={(menu) => (
									// 	<div>
									// 		{menu}
									// 		<Divider />
									// 		<div style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }} onClick={() => setShowAddItemModal(true)}>
									// 			<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
									// 				<PlusOutlined /> Item
									// 			</a>
									// 		</div>
									// 	</div>
									// )}
								>
									{items?.map((item) => (
										<Option key={item?._id} value={item?._id} id={item?.itemName}>
											<section>
												<Col>{`${item?.itemName}`}</Col>
												<Col style={{ fontWeight: 'bold' }}>{convertToIndianRupees(item?.rate)}</Col>
											</section>
										</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label="Wash Type"
								name="categoryId"
								initialValue={editItemRate?.categoryId?._id}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<WashTypeModal />
							</Form.Item>
							<Form.Item
								label="Rate"
								name="rate"
								initialValue={editItemRate?.rate}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input placeholder="rate" />
							</Form.Item>
							<Form.Item
								// style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
								wrapperCol={{
									span: 24,
								}}>
								<Row justify="space-between">
									<Button onClick={() => setItemRateAddModal(false)} style={{ width: '49%' }} danger>
										Cancel
									</Button>
									<Button loading={loading} type="primary" style={{ width: '49%', marginRight: 5 }} htmlType="submit">
										{editItemRate ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddItemRate;
