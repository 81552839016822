import React from 'react';
import { Pagination, Row, Col, Button, Form } from 'antd';
import TableComponent from 'components/table-component';
import AddBilling from 'pages/billings/add-billing';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const TodayDeliveriesListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	state,
	setState,
	editData,
	// rowSelection,
	getBillingItems,
	form,
	fetchingData,
	handleClear,
	updateStatusItems
}) => {
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					loading={fetchingData}
					// rowSelection={rowSelection}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={10} md={10}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getBillingItems}
									{...layer1FormCol}>
									<Row gutter={[20, 0]}>
										<Col xl={24} md={24}>
											<Form.Item
												label="Date"
												name="deliveryDate"
												rules={[
													{
														required: true,
														message: 'This Field is required!',
													},
												]}>
												<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												{/* <Col span={5}></Col> */}
												<Col span={24}>
													<Row gutter={[10, 10]}>
														<Col span={12}>
															<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
																Check Delivery
															</Button>
														</Col>
														<Col span={12}>
															<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
																Clear
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
			<AddBilling {...{ state, setState, refreshList, editData }} />
		</Row>
	);
};

export default TodayDeliveriesListPresentational;
