import React from 'react';
import * as FI from 'react-icons/fi';
import { BsBoxSeam } from 'react-icons/bs';
import * as MD from 'react-icons/md';
import * as RI from 'react-icons/ri';

import Board from 'pages/board';
import Dashboard from 'pages/dashboard';
import StudentsList from 'pages/students/students-list';
import Items from 'pages/items/Items';
// import Master from 'pages/master';
import LoadIn from 'pages/load-in/load-in-list';
import VoucherList from 'pages/voucher/voucher-list';
import TicketList from 'pages/tickets/ticket-list';
import Projects from 'pages/projects';
import CustomerList from 'pages/customers/customer-list';
import UserList from 'pages/users/user-list';
import SuppliersList from 'pages/suppliers/suppliers-list';
import InvoiceList from 'pages/invoice-old/invoice-list';
import AddInvoice from 'pages/invoice-old/add-invoice';
import ServiceTripList from 'pages/service-trips/service-trip-list';
import OrdersList from 'pages/orders/orders-list';
import PaymentsList from 'pages/payments/payments-list';
import TasksList from 'pages/tasks/tasks-list';
import InventoryList from 'pages/inventory/inventory-list';
import LeadsList from 'pages/leads/leads-list';
import Settings from 'pages/settings';
import ProfilePage from 'pages/profile/profile-page';
import PurchasesList from 'pages/purchases/purchases-list';
import Preferences from 'pages/preferences';
import StocksList from 'pages/stocks-new/stocks-list';
import SchedulerList from 'pages/schedulers/scheduler-list';
import CompositeList from 'pages/composites/composites-list';
import BillingsList from 'pages/billings/billings-list';
import UnitSectionsList from 'pages/unit-sections/unit-sections-list';
import TodayDeliveriesList from 'pages/today-deliveries/today-deliveries-list';
import DeliveryAndPaymentsList from 'pages/delivery-and-payments/delivery-and-payments-list';
import Reports from 'pages/reports';
import Master from 'pages/master';
import ItemRate from 'pages/reports/item-rate';
import WashRemark from 'pages/master-component/wash-remarks';

// import * as FA from 'react-icons/fa';
// import { GrVmMaintenance } from 'react-icons/gr';
// import * as FC from 'react-icons/fc';
// import InvoiceList from 'pages/invoice/invoice-list';
// import InvoiceDetail from 'pages/invoice-old/invoice-details';
// import WHInvoice from 'pages/wh-invoice/wh-invoice-list';

// const Board = React.lazy(() => import('pages/board'));
// const Dashboard = React.lazy(() => import('pages/dashboard'));
// const StudentsList = React.lazy(() => import('pages/students/students-list'));
// const Items = React.lazy(() => import('pages/items/Items'));
// const Master = React.lazy(() => import('pages/master'));
// const LoadIn = React.lazy(() => import('pages/load-in/load-in-list'));
// const VoucherList = React.lazy(() => import('pages/voucher/voucher-list'));
// const TicketList = React.lazy(() => import('pages/tickets/ticket-list'));
// const Projects = React.lazy(() => import('pages/projects'));
// const CustomerList = React.lazy(() => import('pages/customers/customer-list'));
// const UserList = React.lazy(() => import('pages/users/user-list'));
// const SuppliersList = React.lazy(() => import('pages/suppliers/suppliers-list'));
// const InvoiceList = React.lazy(() => import('pages/invoice/invoice-list'));
// const AddInvoice = React.lazy(() => import('pages/invoice/add-invoice'));
// const ServiceTripList = React.lazy(() => import('pages/service-trips/service-trip-list'));
// const OrdersList = React.lazy(() => import('pages/orders/orders-list'));
// const PaymentsList = React.lazy(() => import('pages/payments/payments-list'));
// const TasksList = React.lazy(() => import('pages/tasks/tasks-list'));
// const InventoryList = React.lazy(() => import('pages/inventory/inventory-list'));
// const LeadsList = React.lazy(() => import('pages/leads/leads-list'));
// const Settings = React.lazy(() => import('pages/settings'));
// const ProfilePage = React.lazy(() => import('pages/profile/profile-page'));
// const PurchasesList = React.lazy(() => import('pages/purchases/purchases-list'));
// const Preferences = React.lazy(() => import('pages/preferences'));
// const StocksList = React.lazy(() => import('pages/stocks/stocks-list'));
// const SchedulerList = React.lazy(() => import('pages/schedulers/scheduler-list'));
// const CompositeList = React.lazy(() => import('pages/composites/composites-list'));

const API_STATUS = {
	PENDING: 'PENDING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
};

const ACTIONS = {
	ADD_STUDENT: 'ADD_STUDENT',
	ADD_VOUCHER: 'ADD_VOUCHER',
	ADD_VOUCHER_HEAD: 'ADD_VOUCHER_HEAD',
	EDIT_STUDENT: 'EDIT_STUDENT',
	EDIT_VOUCHER: 'EDIT_VOUCHER',
	GET_AGENTS: 'GET_AGENTS',
	GET_INVOICES: 'GET_INVOICES',
	GET_STUDENTS: 'GET_STUDENTS',
	GET_STUDENTS_FEES: 'GET_STUDENTS_FEES',
	GET_VOUCHERS: 'GET_VOUCHERS',
	GET_RECEIPTS: 'GET_RECEIPTS',
	GET_ORDERS: 'GET_ORDERS',
	GET_PURCHASES: 'GET_PURCHASES',
	GET_BILLINGS: 'GET_BILLINGS',
	GET_COMPOSITES: 'GET_COMPOSITES',
	GET_INVENTORIES: 'GET_INVENTORIES',
	GET_TASKS: 'GET_TASKS',
	GET_STOCKS: 'GET_STOCKS',
	GET_LOAD_IN: 'GET_LOAD_IN',
	GET_VOUCHERS_HEAD: 'GET_VOUCHERS_HEAD',
	GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
	GET_ITEMS: 'GET_ITEMS',
	GET_PROJECTS: 'GET_PROJECTS',
	GET_CLASSES: 'GET_CLASSES',
	GET_ACCOUNT_BOOKS: 'GET_ACCOUNT_BOOKS',
	GET_SALES_PERSONS: 'GET_SALES_PERSONS',
	GET_WASH_REMARKS: 'GET_WASH_REMARKS',
	SET_WASH_REMARKS: 'SET_WASH_REMARKS',
	GET_ITEM_RATES: 'GET_ITEM_RATES',
	SET_ITEM_RATES: 'SET_ITEM_RATES',
	SET_ACCOUNT_BOOKS: 'SET_ACCOUNT_BOOKS',
	SET_SALES_PERSONS: 'SET_SALES_PERSONS',
	GET_TICKETS: 'GET_TICKETS',
	GET_SERVICE_TRIPS: 'GET_SERVICE_TRIPS',
	SET_SERVICE_TRIPS: 'SET_SERVICE_TRIPS',
	GET_TICKETS_DETAILS: 'GET_TICKETS_DETAILS',
};

export const DATE_FORMAT = {
	YYYY_MM_DD: 'YYYY-MM-DD',
	DD_MM_YYYY: 'DD-MM-YYYY',
	MM_DD_YYYY: 'MM-DD-YYYY',
};

const ADMIN_SIDE_MENUS = [
	{
		name: 'Users',
		icon: <FI.FiUsers />,
		route: '/users',
		key: '1',
	},
];

const SIDE_MENUS = [];

const MENUS = [
	{
		name: 'Dashboard',
		icon: <MD.MdDashboard />,
		route: '/',
		key: '1',
	},
	{
		name: 'Billing',
		icon: <FI.FiUsers />,
		route: '/billings',
		key: '2',
	},
	{
		name: 'Delivery & Payments',
		icon: <RI.RiBillLine />,
		route: '/delivery-and-payments',
		key: '3',
	},
	{
		name: 'Vouchers',
		icon: <BsBoxSeam />,
		route: '/voucher',
		key: '4',
	},
	{
		name: 'Reports',
		icon: <FI.FiSettings />,
		route: '/reports',
		key: '5',
	},
	{
		name: 'Unit Sections',
		icon: <RI.RiBillLine />,
		route: '/unit-sections',
		key: '6',
	},
	{
		name: 'Today Deliveries',
		icon: <RI.RiBillLine />,
		route: '/today-deliveries',
		key: '7',
	},
	{
		name: 'Customers',
		icon: <FI.FiUsers />,
		route: '/customers',
		key: '8',
	},
	{
		name: 'Wash Remarks',
		icon: <FI.FiSettings />,
		route: '/wash-remarks',
		key: '9',
	},
];

const ADMIN_MENUS = [
	{
		name: 'Dashboard',
		icon: <MD.MdDashboard />,
		route: '/',
		key: '10',
	},
	{
		name: 'Billing',
		icon: <FI.FiUsers />,
		route: '/billings',
		key: '11',
	},
	{
		name: 'Delivery & Payments',
		icon: <RI.RiBillLine />,
		route: '/delivery-and-payments',
		key: '12',
	},
	{
		name: 'Vouchers',
		icon: <BsBoxSeam />,
		route: '/voucher',
		key: '13',
	},
	{
		name: 'Reports',
		icon: <FI.FiSettings />,
		route: '/reports',
		key: '14',
	},
	{
		name: 'Unit Sections',
		icon: <RI.RiBillLine />,
		route: '/unit-sections',
		key: '15',
	},
	{
		name: 'Today Deliveries',
		icon: <RI.RiBillLine />,
		route: '/today-deliveries',
		key: '16',
	},
	{
		name: 'Customers',
		icon: <FI.FiUsers />,
		route: '/customers',
		key: '17',
	},
	{
		name: 'Items',
		icon: <RI.RiBillLine />,
		route: '/items',
		key: '18',
	},
	{
		name: 'Item Rate',
		icon: <FI.FiSettings />,
		route: '/item-rate',
		key: '19',
	},
	{
		name: 'Wash Remarks',
		icon: <FI.FiSettings />,
		route: '/wash-remarks',
		key: '20',
	},
];

export const ROUTE_CONSTANTS = {
	NEW_INVOICE: '/invoice/new',
	INVOICE_DETAILS: '/invoice',
};

const ROUTES = [
	// {
	// 	route: '/invoice/:id',
	// 	Component: InvoiceDetail,
	// },
	{
		route: '/invoice/:invoiceId',
		Component: AddInvoice,
	},
	{
		route: '/invoice/new',
		Component: AddInvoice,
	},
	{
		route: '/ticket/:selectedTicketId',
		Component: TicketList,
	},
	{
		route: '/projects',
		Component: Projects,
	},
	{
		route: '/tickets',
		Component: TicketList,
	},
	{
		route: '/students',
		Component: StudentsList,
	},
	{
		route: '/customers',
		Component: CustomerList,
	},
	{
		route: '/schedulers',
		Component: SchedulerList,
	},
	{
		route: '/composites',
		Component: CompositeList,
	},
	{
		route: '/settings',
		Component: Settings,
	},
	{
		route: '/profile',
		Component: ProfilePage,
	},
	{
		route: '/suppliers',
		Component: SuppliersList,
	},
	{
		route: '/leads',
		Component: LeadsList,
	},
	{
		route: '/orders',
		Component: OrdersList,
	},
	{
		route: '/inventory',
		Component: InventoryList,
	},
	{
		route: '/purchases',
		Component: PurchasesList,
	},
	{
		route: '/stocks',
		Component: StocksList,
	},
	{
		route: '/users',
		Component: UserList,
	},
	{
		route: '/items',
		Component: Items,
	},
	{
		route: '/reports',
		Component: Reports,
	},
	{
		route: '/item-rate',
		Component: ItemRate,
	},
	{
		route: '/invoice',
		Component: InvoiceList,
	},
	{
		route: '/service-trip',
		Component: ServiceTripList,
	},
	{
		route: '/voucher',
		Component: VoucherList,
	},
	{
		route: '/board',
		Component: Board,
	},
	{
		route: '/tickets',
		Component: TicketList,
	},
	{
		route: '/received-entry',
		Component: LoadIn,
	},
	{
		route: '/payments',
		Component: PaymentsList,
	},
	{
		route: '/unit-sections',
		Component: UnitSectionsList,
	},
	{
		route: '/today-deliveries',
		Component: TodayDeliveriesList,
	},
	{
		route: '/delivery-and-payments',
		Component: DeliveryAndPaymentsList,
	},
	{
		route: '/tasks',
		Component: TasksList,
	},
	{
		route: '/masters',
		Component: Master,
	},
	{
		route: '/preferences',
		Component: Preferences,
	},
	{
		route: '/billings',
		Component: BillingsList,
	},
	{
		route: '/wash-remarks',
		Component: WashRemark,
	},
	{
		route: '/',
		Component: Dashboard,
	},
];

export { ACTIONS, MENUS, ROUTES, API_STATUS, SIDE_MENUS, ADMIN_SIDE_MENUS, ADMIN_MENUS };

const CUSTOMER_TYPE = ['Customer', 'Supplier', 'Lead', 'Contact'];
const CATEGORIES = ['Individual', 'Business'];
const USER_TYPE = ['Admin', 'Staff'];
const GST_TREATMENT = [
	{ label: 'Consumer', value: 'consumer' },
	{ label: 'Registered Business', value: 'registered' },
];
const PLACE_OF_SUPPLY = ['Tamilnadu', 'Others'];
const SCHEDULER_TYPE = ['Service'];

const STATUS_DROPDOWN = ['Open', 'Assigned', 'Accepted', 'In Progress', 'Completed', 'Cancelled', 'Pending'];
const ORDER_STATUS_DROPDOWN = ['Pending', 'Ready to Dispatch', 'Completed', 'Cancelled'];

// const STATUS_DROPDOWN_FORMATTED = STATUS_DROPDOWN.map((status) => ({
// 	label: status,
// 	value: status,
// }));

export const STATUS = {
	Pending: 'processing',
	InTransit: 'processing',
	Adjusted: 'processing',
	Active: 'processing',
	InStock: 'success',
	Completed: 'success',
	Dispatched: 'success',
	Add: 'success',
	'Ready to Dispatch': 'warning',
	Halting: 'warning',
	Reduce: 'error',
	Cancelled: 'error',
};

export { CUSTOMER_TYPE, GST_TREATMENT, PLACE_OF_SUPPLY, SCHEDULER_TYPE, USER_TYPE, CATEGORIES, STATUS_DROPDOWN, ORDER_STATUS_DROPDOWN };

export const NOTIFICATION_STATUS_TYPES = {
	SUCCESS: 'SUCCESS',
	INFO: 'INFO',
	ERROR: 'ERROR',
	WARNING: 'WARNING',
};

export const DEPARTMENT_LIST = ['Rufcasting', 'Scrap', 'Rejection', 'Others ', 'Invoice '];
export const PRIORITIES = ['None', 'Low', 'Medium', 'High'];

export const ITEM_TYPES = [
	{ label: 'Goods', value: 'Goods' },
	{ label: 'Service', value: 'Service' },
	{ label: 'Digital', value: 'Digital' },
];

export const SERIAL_TYPE = {
	ADD: 'ADD',
	REMOVE: 'REMOVE',
};
