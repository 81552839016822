import React, { useCallback } from 'react';
import moment from 'moment';
import { Button, Select, Modal, Drawer, Row, Col, Input, Form, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import TableComponent from 'components/table-component';
import DatePicker from 'components/date-picker';
import { DATE_FORMAT } from 'constants/app-constants';
import { inWords } from 'services/Utils';
import AddSerialModal from 'components/add-serial-modal';

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const AddBillingPresentational = ({
	state,
	setState,
	handleSubmit,
	columns,
	loading,
	editData,
	form,
	customers,
	isValid,
	totalAmount,
	roundOff,
	setCustomerAddModal,
	handleInputChange,
	selectedData,
	setSelectedData,
	tableData,
	setTableData,
	groupByColumns,
	groupData,
	discount,
	setDiscount,
	subTotal,
	totalQty,
	billItemLoading,
	showPropsConfirm,
	remark3Data,
	remark4Data,
	remark3Id,
	setRemark3Id,
	remark4Id, 
	setRemark4Id,
	washTypes,
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState((state) => ({ ...state, visible: false }));
			},
			onCancel() {},
		});
	}, [setState]);

	return (
		<>
			<AddSerialModal
				visible={!!selectedData}
				handleInputChange={handleInputChange}
				selectedData={selectedData}
				setSelectedData={setSelectedData}
				tableData={tableData}
				setTableData={setTableData}
			/>
			<Drawer
				maskClosable={false}
				title={`${editData ? 'Edit' : 'Create'} Billing`}
				placement="right"
				width={'90%'}
				open={state?.visible}
				destroyOnClose
				className="add_inventory"
				onClose={showConfirm}
				footer={
					<Row>
						<Col
							xl={{
								span: 9,
								offset: 15,
							}}
							md={12}>
							<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
								<Col>
									<Button onClick={() => setState({ ...state, visible: false })}>Cancel</Button>
								</Col>
								<Col>
									<Button disabled={!isValid || !remark3Id || !remark4Id} loading={loading} type="primary" onClick={showPropsConfirm}>
										{`${editData ? 'Update' : 'Submit'}`}
									</Button>
									{/* <Button disabled={!isValid} loading={loading} type="primary" htmlType="submit" onClick={() => form.submit()}>
										{`${editData ? 'Update' : 'Submit'}`}
									</Button> */}
								</Col>
							</Row>
						</Col>
					</Row>
				}>
				<Row justify="start">
					<Col xl={24} md={24}>
						<TableComponent
							{...{
								columns,
								dataSource: tableData,
								pagination: false,
								loading: billItemLoading,
								// footer: () => (
								// 	<Row style={{ fontSize: 18 }}>
								// 		<Col className="bold" style={{ paddingRight: 10 }}>
								// 			Total:
								// 		</Col>
								// 		<Col className="bold">{totalAmount}</Col>
								// 	</Row>
								// ),

								footer: () => (
									<Row style={{ fontSize: 14 }}>
										<Col xl={18} md={18}>
											<Row align={'middle'} style={{ paddingBottom: 20 }}>
												<Col xl={3} md={3} style={{ paddingRight: 10 }}>
													Stain<span style={{color:"#ff4d4f"}}>*</span>
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col xl={6} md={6} style={{ fontWeight: 'bold' }}>
													<Select
														// value={remark3Id}
														{...{
															...(remark3Id && {
																value: remark3Id,
															}),
														}}
														style={{ width: '100%', textAlign: 'left' }}
														placeholder="Select Stain"
														onChange={(value) => setRemark3Id(value)}>
														{remark3Data?.map((remark1) => (
															<Select.Option key={remark1?._id} value={remark1?._id} id={remark1?._id}>
																{remark1?.remark}
															</Select.Option>
														))}
													</Select>
												</Col>
											</Row>
											<Row align={'middle'} style={{ paddingBottom: 20 }}>
												<Col xl={3} md={3} style={{ paddingRight: 10 }}>
													Damage<span style={{color:"#ff4d4f"}}>*</span>
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col xl={6} md={6} style={{ fontWeight: 'bold' }}>
													<Select
														{...{
															...(remark4Id && {
																value: remark4Id,
															}),
														}}
														style={{ width: '100%', textAlign: 'left' }}
														placeholder="Select Damage"
														onChange={(value) => setRemark4Id(value)}>
														{remark4Data?.map((remark1) => (
															<Select.Option key={remark1?._id} value={remark1?._id} id={remark1?._id}>
																{remark1?.remark}
															</Select.Option>
														))}
													</Select>
												</Col>
											</Row>
											<Col xl={24} md={24} className="bold" style={{ paddingRight: 10 }}>
												Amount in words
											</Col>
											<Col xl={24} md={24}>
												{inWords(roundOff?.value)}
											</Col>
										</Col>
										<Col xl={6} md={6}>
											<Row justify="center" align="middle" style={{ padding: '10px 0' }}>
												<Col
													xl={14}
													md={14}
													style={{
														textAlign: 'right',
														paddingRight: 10,
														fontWeight: 'bold',
														fontSize: '1.5rem',
														color: '#66a4f7',
													}}>
													Total Qty
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col
													xl={9}
													md={9}
													style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '1.5rem', color: '#66a4f7' }}>
													{totalQty}
												</Col>
											</Row>
											<Row>
												<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
													Sub Total
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
													{parseFloat(subTotal).toFixed(2)}
												</Col>
											</Row>
											<Row>
												<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
													Discount
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
													<Input
														value={discount}
														style={{ textAlign: 'right' }}
														onChange={({ target: { value } }) => setDiscount(value)}
														placeholder="discount"
													/>
												</Col>
											</Row>
											<Row>
												<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
													Round Off (+/-)
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
													{roundOff?.remain || 0}
												</Col>
											</Row>
											<Row>
												<Col
													xl={14}
													md={14}
													style={{
														textAlign: 'right',
														paddingRight: 10,
														fontWeight: 'bold',
														fontSize: '1.3rem',
													}}>
													Grand Total
												</Col>
												<Col xl={1} md={1}>
													:
												</Col>
												<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '1.3rem' }}>
													{parseFloat(roundOff.value).toFixed(2)}
												</Col>
											</Row>
										</Col>
									</Row>
								),
								title: () => (
									<Row justify="space-between">
										<Col xl={24} md={24}>
											<Form
												labelAlign="left"
												name="add-inventory"
												className="required_in_right"
												form={form}
												onFinish={handleSubmit}
												{...layer1FormCol}>
												<Row gutter={[20, 20]}>
													<Col xl={8} md={8}>
														<Form.Item
															label="Customer"
															name="customerId"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select
																showSearch
																optionFilterProp="children"
																filterOption={(input, option) => {
																	return (
																		option.id?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
																		option.key?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
																	);
																}}
																disabled={!!editData}
																placeholder="Select Customer"
																dropdownRender={(menu) => (
																	<div>
																		{menu}
																		<Divider />
																		<div
																			style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }}
																			onClick={() => setCustomerAddModal(true)}>
																			<a
																				href
																				style={{
																					flex: 'none',
																					color: '#188dfa',
																					padding: '8px',
																					display: 'block',
																				}}>
																				Add new Customer
																			</a>
																		</div>
																	</div>
																)}>
																{customers?.map((customer) => (
																	<Select.Option
																		value={customer?._id}
																		key={customer?.mobile}
																		id={customer?.displayName}>
																		<section>
																			<Col style={{ padding: '0 0' }}>
																				<Col>{`${customer?.displayName}`}</Col>
																				<Col>{`${customer?.mobile}`}</Col>
																			</Col>
																		</section>
																	</Select.Option>
																))}
															</Select>
														</Form.Item>
													</Col>
													<Col xl={8} md={8}>
														<Form.Item
															label="Bill Date"
															name="billDate"
															initialValue={moment()}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
														</Form.Item>
													</Col>
													<Col xl={8} md={8}>
														<Form.Item
															label="Delivery Date"
															name="deliveryDate"
															initialValue={moment().add('days', 5)}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
														</Form.Item>
													</Col>
													<Col xl={8} md={8}>
														<Form.Item
															label="Wash Type"
															name="categoryId"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select disabled={!!editData} placeholder="Select Wash Type">
																{washTypes?.map((washType) => (
																	<Select.Option value={washType?._id} key={washType?._id} id={washType?._id}>
																		{washType?.categoryName}
																	</Select.Option>
																))}
															</Select>
														</Form.Item>
													</Col>
													<Col xl={8} md={8}>
														<Form.Item label="Remarks" name="remarks">
															<Input.TextArea style={{ width: '100%' }} placeholder="enter remarks if any" />
														</Form.Item>
													</Col>
												</Row>
											</Form>
										</Col>
									</Row>
								),
							}}
						/>
					</Col>
					{/* <Col xl={12} md={12} style={{ paddingTop: 10 }}>
						<TableComponent
							{...{
								columns: groupByColumns,
								dataSource: groupData,
								pagination: false,
								className: 'custom-table'
							}}
						/>
					</Col> */}
				</Row>
			</Drawer>
		</>
	);
};

export default AddBillingPresentational;
