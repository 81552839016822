import React, {
	useRef,
	// useState,
	useEffect,
} from 'react';
import {
	Row,
	Col,
	Spin,
	Button,
	// Tour,
	// Select
} from 'antd';
import // LineChartOutlined,
// WarningOutlined,
// PieChartOutlined,
'@ant-design/icons';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineShopping } from 'react-icons/ai';
import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi';
// import { LineChartGradient } from 'components/charts/line-chart';
// import { generateGreetings } from 'helpers';
// import { PieChart } from 'components/charts/pie-chart';
// import { LineChart } from 'components/charts/bar-chart';
import AnimatedNumber from 'components/animated-number';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';
import { RechartsLineChart } from 'components/charts/recharts-line-chart';
import TicketTable from './ticket-table';
import { ReloadOutlined } from '@ant-design/icons';

const DashboardPresentational = ({ dashboardData, tableData, loginRedux, loading, getDashboardData, specificDate, setSpecificDate }) => {
	// const [open, setOpen] = useState(false);
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	//   const ref3 = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			// setOpen(true);
		}, 500);
	}, []);

	// const steps = [
	// 	{
	// 		title: 'Upload File',
	// 		description: 'Put your files here.',
	// 		cover: <img alt="tour.png" src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png" />,
	// 		target: () => ref1.current,
	// 	},
	// 	{
	// 		title: 'Save',
	// 		description: 'Save your changes.',
	// 		target: () => ref2.current,
	// 	},
	// ];
	return (
		<Row className="dashboard_container">
			{loading && (
				<div className="dashboard_container_loader">
					<Spin />
				</div>
			)}
			<Col xl={24}>
				<Row gutter={[10, 10]}>
					<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row>
							<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h4>
									Hi there, {loginRedux?.firstName} {loginRedux?.lastName}! 👋
								</h4>
								<div>
									<DatePicker
										style={{ width: 150 }}
										format={DATE_FORMAT.DD_MM_YYYY}
										value={specificDate}
										onChange={(date, dateString) => setSpecificDate(date)}
									/>
									<Button
										type="primary"
										icon={<ReloadOutlined />}
										loading={loading}
										onClick={getDashboardData}
										style={{ marginLeft: 10 }}
									/>
								</div>
								{/* <div>
									{generateGreetings()}! {loginRedux?.firstName} {loginRedux?.lastName}
								</div> */}
							</Col>
							{/* <Col span={12} style={{ textAlign: 'right' }}>
								<Select
									placeholder="Select Branch"
									allowClear
									style={{ width: 150 }}
									onChange={(value) => setSelectedBranchId?.(value)}>
									{branches?.map((branch) => (
										<Select.Option key={branch?._id} value={branch?._id}>
											{branch?.areaName}
										</Select.Option>
									))}
								</Select>
							</Col> */}
						</Row>
					</Col>

					<Col span={24} ref={ref1}>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalCustomers} />
														</div>
														<div className="name">Total Customers</div>
													</Col>
													<Col span={5} className="icon">
														<BsFillPersonFill />
													</Col>
												</Row>
											</Col>
											{/* <Col span={24} className="desc">
										Customers in this app
									</Col> */}
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.unpaidBills} />
														</div>
														<div className="name">UnPaid Bills</div>
													</Col>
													<Col span={5} className="icon red">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalBalance?.[0]?.totalBalance || 0} />
														</div>
														<div className="name">Total Balance</div>
													</Col>
													<Col span={5} className="icon ">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.todayExpenses?.[0]?.totalDebit || 0} />
														</div>
														<div className="name">Today Expenses</div>
													</Col>
													<Col span={5} className="icon red">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.todayCollection?.[0]?.todayCollection || 0} />
														</div>
														<div className="name">Today Collections</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									{/* <Col span={5}>
								<Row className="dashboard_card">
									<Col span={24}>
										<Row align="top">
											<Col span={19}>
												<div className="value">
													<AnimatedNumber value={dashboardData?.totalTickets?.length} />
												</div>
												<div className="name">Total Tickets</div>
											</Col>
											<Col span={5} className="icon">
												<PieChartOutlined />
											</Col>
										</Row>
									</Col>
									<Col span={24} className="desc">
										Tickets in this app
									</Col>
								</Row>
							</Col> */}
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.orders?.map((order, index) => (
										<Col key={index} span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={order?.count || 0} />
															</div>
															<div className="name">{order?._id || ''}</div>
														</Col>
														<Col span={5} className="icon">
															<AiOutlineShopping />
														</Col>
													</Row>
												</Col>
												{/* <Col span={24} className="desc">
										Customers in this app
									</Col> */}
											</Row>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={24} ref={ref1}>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.unit_Pending} />
														</div>
														<div className="name">Unit Pending</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowDownLeft />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.unit_Sent} />
														</div>
														<div className="name">Unit Sent</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.deliveryPending} />
														</div>
														<div className="name">Delivery Pending</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowDownLeft />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.orders?.map((order, index) => (
										<Col key={index} span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={order?.count || 0} />
															</div>
															<div className="name">{order?._id || ''}</div>
														</Col>
														<Col span={5} className="icon">
															<AiOutlineShopping />
														</Col>
													</Row>
												</Col>
												{/* <Col span={24} className="desc">
										Customers in this app
									</Col> */}
											</Row>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Col>

					{/* <Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row>
							<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Tickets</div>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row gutter={[10, 10]}>
							{dashboardData?.totalTickets?.map((data) => (
								<Col span={5}>
									<Row className="dashboard_card">
										<Col span={24}>
											<Row align="top">
												<Col span={19}>
													<div className="value">
														<AnimatedNumber value={data?.count} />
													</div>
													<div className="name">{data?._id} Tickets</div>
												</Col>
												<Col span={5} className="icon">
													<PieChartOutlined />
												</Col>
											</Row>
										</Col>
										<Col span={24} className="desc">
											{data?._id} Tickets in this app
										</Col>
									</Row>
								</Col>
							))}
						</Row>
					</Col> */}

					{(tableData || [])?.length > 0 ? (
						<Col xl={24} style={{ paddingTop: 20 }} ref={ref2}>
							<Row gutter={[10, 10]}>
								<Col xl={24} style={{ paddingTop: 20 }}>
									<Row gutter={[10, 10]}>
										<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
											<div>Tickets</div>
										</Col>
									</Row>
								</Col>
								<Col className="title" span={14}>
									<TicketTable tableData={tableData} />
								</Col>
							</Row>
						</Col>
					) : null}
					{/* <Col xl={24} style={{ paddingTop: 20 }}>
						<Row gutter={[10, 10]}>
							<Col xl={24} style={{ paddingTop: 20 }}>
								<Row gutter={[10, 10]}>
									<Col className="title" span={24}>
										Payment History
									</Col>
								</Row>
							</Col>
							<Col
								className="title"
								span={16}
								style={{
									padding: '20px 0',
								}}>
								<Col>
									<table className="bordered-bottom" id="payments">
										<tr>
											<th>
												<span style={{ paddingRight: 5 }}>RECENT PAYMENTS</span> <ReloadOutlined />
											</th>
											<th></th>
											<th></th>
											<th style={{ textAlign: 'right' }}>ALL PAYMENTS</th>
										</tr>
										{Array(5)
											.fill('')
											.map((data) => (
												<tr>
													<td>about 2 years</td>
													<td>CCTV</td>
													<td>Ravi Chandran</td>
													<td style={{ textAlign: 'right' }}>₹1,200</td>
												</tr>
											))}
									</table>
								</Col>
							</Col>
						</Row>
					</Col> */}
					{/* <Col xl={24} style={{ paddingTop: 20 }}>
						<Row gutter={[10, 10]}>
							<Col xl={24} style={{ paddingTop: 20 }}>
								<Row gutter={[10, 10]}>
									<Col className="title" span={24}>
										Amount Collection history
									</Col>
									{dashboardData?.areaSummary?.map((areaSummary) => (
										<Col span={5}>
											<Row className="dashboard_card">
												<Col span={24} className="icon">
													<LineChartOutlined />
												</Col>
												<Col
													span={24}
													style={{
														fontSize: '1.3rem',
														lineHeight: 'normal',
														// minHeight: 60,
														padding: '10px 0',
														display: 'flex',
														alignItems: 'center',
													}}
													className="value">
													{areaSummary?.areaName}
												</Col>
												<Col span={24} className="name">
													Total Cust. :{' '}
													<span style={{ fontWeight: 'bold' }}>
														<AnimatedNumber value={areaSummary?.totalCustomers} />
													</span>
												</Col>
												<Col span={24} className="name">
													Active Cust. :{' '}
													<span style={{ fontWeight: 'bold' }}>
														<AnimatedNumber value={areaSummary?.activeCustomers} />
													</span>
												</Col>
												<Col span={24} className="name" style={{ color: '#d52b1e' }}>
													Pending Amnt. :{' '}
													<span style={{ fontWeight: 'bold' }}>
														<AnimatedNumber value={areaSummary?.PendingAmount} />
													</span>
												</Col>
											</Row>
										</Col>
									))}
								</Row>
							</Col>
							<Col
								className="title"
								span={24}
								style={{
									padding: '20px 0',
								}}>
								<LineChartGradient />
							</Col>
						</Row>
					</Col> */}
				</Row>
				{loginRedux?.role === 'admin' && (
					<Row gutter={[20, 20]} justify="space-between">
						{/* <Col
						className="title"
						span={8}
						style={{
							paddingRight: 10,
						}}>
						<Col
							style={{
								padding: 20,
								backgroundColor: '#fff',
								borderRadius: 8,
							}}>
							<PieChart />
						</Col>
					</Col>
					<Col
						className="title"
						span={16}
						style={{
							padding: 20,
							backgroundColor: '#fff',
							borderRadius: 8,
						}}>
						<LineChart />
					</Col> */}
						{dashboardData?.dailySalesSummary?.length > 0 && (
							<Col
								span={16}
								style={
									{
										// padding: "20px 0",
									}
								}>
								<div className="chart_container" style={{ marginLeft: 0 }}>
									<div className="chart_title">Daily Sales</div>
									<RechartsLineChart data={dashboardData?.dailySalesSummary || []} />
								</div>
							</Col>
						)}
					</Row>
				)}
			</Col>
			{/* <Tour open={open} onClose={() => setOpen(false)} steps={steps} /> */}
		</Row>
	);
};

export default DashboardPresentational;
