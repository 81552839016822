import React from 'react';
import { Row, Col, Button, Form, Pagination, Select } from 'antd';
import TableComponent from 'components/table-component';
// import moment from 'moment';
// import { DATE_FORMAT } from 'constants/app-constants';
// import DatePicker from 'components/date-picker';

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const StockReportPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	getBillingItems,
	form,
	fetchingData,
	handleClear,
	setSelectedRecordToPrint,
}) => {
	return (
		<Row>
			<Col span={16} style={{ padding: 20 }}>
				<TableComponent
					loading={fetchingData}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={24} md={24}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getBillingItems}
									{...layer1FormCol}>
									<Row gutter={[20, 0]}>
										<Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														initialValue="Stock In Shop"
														label="Stock report by"
														rules={[{ required: true }]}
														name="stockReportBy">
														<Select allowClear placeholder="select stock report by">
															<Select.Option value={'Stock In Shop'}>Stock In Shop</Select.Option>
															<Select.Option value={'Stock in Unit'}>Stock in Unit</Select.Option>
														</Select>
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														initialValue="Paid"
														label="Payment Status"
														rules={[{ required: false }]}
														name="paymentStatus">
														<Select allowClear placeholder="select stock report by">
															<Select.Option value={'Paid, Pending'}>All</Select.Option>
															<Select.Option value={'Paid'}>Paid</Select.Option>
															<Select.Option value={'Pending'}>Unpaid</Select.Option>
														</Select>
													</Form.Item>
												</Col>
											</Row>
										</Col>
										{/* <Col xl={12} md={12}>
											<Row>
												<Col span={24}>
													<Form.Item
														label="From Date"
														name="fromDate"
														initialValue={moment()}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item
														label="To Date"
														name="toDate"
														initialValue={moment()}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
													</Form.Item>
												</Col>
											</Row>
										</Col> */}
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												<Col span={12}>
													<Row gutter={[10, 10]}>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
																Clear
															</Button>
														</Col>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
																Submit
															</Button>
														</Col>
														{filteredData?.length > 0 && (
															<Col span={8}>
																<Button
																	onClick={() =>
																		setSelectedRecordToPrint({ ...form.getFieldsValue(), data: filteredData })
																	}
																	type="primary"
																	style={{ width: '100%' }}>
																	Print
																</Button>
															</Col>
														)}
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
		</Row>
	);
};

export default StockReportPresentational;
