import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDateFormat } from 'services/Utils';
import { DATE_FORMAT } from 'constants/app-constants';
import './unit-report-to-print.scss';

const UnitReportToPrint = forwardRef((props, ref) => {
	// const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	// const { voucherHeads = [] } = globalRedux;
	const { data, fromBill, toBill, toDate, fromDate } = props?.data || {};
	console.log("🚀 ~ file: unit-report-to-print.js:13 ~ UnitReportToPrint ~ props?.data:", props?.data)
	// const voucherHead = voucherHeads?.find((voucherHead) => voucherHead?._id === voucherHeadId);

	return (
		<div className="invoice-pdf-container" ref={ref}>
			<div style={{ fontWeight: 'bold', textAlign: 'center' }}>Unit Report</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td
						colSpan="3"
						style={{
							borderLeftWidth: 0,
						}}>
						<table border="1" className="top-heading">
							<tr>
								<td>
									<div className="content">
										<div style={{}}>
											<div className="title" style={{ width: '50%' }}>
												From Bill: <span style={{ fontWeight: 'normal' }}>{fromBill || ''}</span>
											</div>
											<div className="title" style={{ width: '50%' }}>
												To Bill: <span style={{ fontWeight: 'normal' }}>{toBill || ''}</span>
											</div>
										</div>
									</div>
								</td>
								<td className="value_container">
									<div className="content">
										<div style={{}}>
											<div className="title" style={{ width: '50%' }}>
												From Date:{' '}
												<span style={{ fontWeight: 'normal' }}>
													{fromDate ? moment(fromDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
												</span>
											</div>
											<div className="title" style={{ width: '50%' }}>
												To Date:{' '}
												<span style={{ fontWeight: 'normal' }}>
													{toDate ? moment(toDate).format(DATE_FORMAT.DD_MM_YYYY) : ''}
												</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td colSpan="3" style={{ padding: 0, borderLeftWidth: 0 }}>
									<table>
										<tr>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													// borderLeftWidth: 0,
													textAlign: 'center',
													borderLeftWidth: 1,
												}}>
												Bill.No
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													padding: 5,
													textAlign: 'center',
												}}>
												Stain
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Damage
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Delivery Date
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Wash Type
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Item Name
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Starch
											</td>
											<td style={{ fontWeight: 'bold', borderTopWidth: 0, borderBottomWidth: 1, textAlign: 'center' }}>
												Shades
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 5, textAlign: 'right' }}>Qty</div>
											</td>
										</tr>
										{(data || [])?.map((data, dataIndex, dataArr) => (
											<>
												{data?.items?.map((item, index, itemArr) => (
													<>
														<tr>
															{index === 0 && (
																<td
																	rowSpan={data?.items?.length}
																	style={{
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		// borderBottomWidth: index + 1 === 10 ? 0 : 1,
																		// borderLeftWidth: 0,
																		textAlign: 'center',
																		height: 30,
																		borderLeftWidth: 1,
																	}}>
																	{data?.billNumber}
																</td>
															)}
															{index === 0 && (
																<td
																	rowSpan={data?.items?.length}
																	style={{
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		// borderBottomWidth: index + 1 === 10 ? 0 : 1,
																		padding: 5,
																		height: 30,
																		textAlign: 'center',
																	}}>
																	{data?.remark3Id?.remark}
																</td>
															)}
															{index === 0 && (
																<td
																	rowSpan={data?.items?.length}
																	style={{
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		// borderBottomWidth: index + 1 === 10 ? 0 : 1,
																		textAlign: 'center',
																		height: 30,
																	}}>
																	{data?.remark4Id?.remark}
																</td>
															)}
															{index === 0 && (
																<td
																	rowSpan={data?.items?.length}
																	style={{
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		// borderBottomWidth: index + 1 === 10 ? 0 : 1,
																		textAlign: 'center',
																		height: 30,
																	}}>
																	{data?.deliveryDate
																		? moment(data?.deliveryDate).format(DATE_FORMAT.DD_MM_YYYY)
																		: ''}
																</td>
															)}
															{index === 0 && (
																<td
																	rowSpan={data?.items?.length}
																	style={{
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		// borderBottomWidth: index + 1 === 10 ? 0 : 1,
																		textAlign: 'center',
																		height: 30,
																	}}>
																	{data?.categoryId?.categoryName || ''}
																</td>
															)}
															<td
																style={{
																	borderTopWidth: 0,
																	borderBottomWidth: 1,
																	textAlign: 'center',
																	height: 30,
																}}>
																{item?.itemId?.itemName}
															</td>
															<td
																style={{
																	borderTopWidth: 0,
																	borderBottomWidth: 1,
																	textAlign: 'center',
																	height: 30,
																}}>
																{item?.remark1Id?.remark || ''}
															</td>
															<td
																style={{
																	borderTopWidth: 0,
																	borderBottomWidth: 1,
																	textAlign: 'center',
																	height: 30,
																}}>
																{item?.remark2Id?.remark || ''}
															</td>
															<td
																style={{
																	borderTopWidth: 0,
																	borderBottomWidth: 1,
																	borderRightWidth: 0,
																	textAlign: 'right',
																	height: 30,
																}}>
																<div style={{ paddingRight: 5 }}>{item?.qty}</div>
															</td>
														</tr>
														{itemArr?.length === index + 1 && (
															<>
																<tr
																	style={{
																		borderLeft: 0,
																	}}>
																	<td
																		colSpan="5"
																		style={{
																			borderLeftWidth: 0,
																			borderBottom: 0,
																			borderRightWidth: 0,
																		}}>
																		<div className="" style={{}}>
																			<div style={{ textAlign: 'right', fontWeight: 'bold' }}></div>
																		</div>
																	</td>
																	<td
																		colSpan="2"
																		style={{
																			borderLeftWidth: 0,
																			borderBottom: 0,
																			borderRightWidth: 0,
																		}}>
																		<div className="" style={{}}>
																			<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5 }}>
																				Bill Qty
																			</div>
																		</div>
																	</td>
																	<td
																		colSpan="2"
																		style={{ borderRightWidth: 0, borderLeftWidth: 0, borderBottom: 0 }}>
																		<div className="" style={{}}>
																			<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5 }}>
																				{data?.totalQty}
																			</div>
																		</div>
																	</td>
																</tr>
																<tr
																	style={{
																		border: 0,
																	}}>
																	<td
																		colSpan="9"
																		style={{
																			borderTop: 0,
																			borderRight: 0,
																			borderLeft: 0,
																			// borderBottom: dataArr?.length === dataIndex + 1 ? 0 : 1,
																			// borderLeftWidth: 0,
																		}}>
																		<div className="" style={{ height: 10 }}></div>
																	</td>
																</tr>
															</>
														)}
													</>
												))}
											</>
										))}
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<div className="">
										<div style={{ textAlign: 'right', fontWeight: 'bold', paddingRight: 5, fontSize: '1rem' }}>
											Total Report Qty:{' '}
											<span style={{ marginLeft: 10 }}>{data?.reduce((acc, prev) => acc + prev?.totalQty, 0)}</span>
										</div>
									</div>
								</td>
							</tr>
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td style={{ width: '50%' }}>
									<div className="content" style={{}}>
										<div style={{ textAlign: 'right' }}>
											Printed by {loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
								</td>
								<td style={{ width: '50%' }}>
									<div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default UnitReportToPrint;
