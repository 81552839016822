import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'antd';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import HighlightComponent from 'components/HighlightComponent';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import ComponentToPrint from 'components/component-to-print';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { sendGetRequest, sendPostRequest } from 'redux/sagas/utils';
import TodayDeliveriesListPresentational from './today-deliveries-list-presenatational';
import { formQueryStringFromObject } from 'helpers';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const TodayDeliveriesListFunctional = React.memo(() => {
	const [form] = Form.useForm();
	const [fetchingData, setFetchingData] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState('');
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const dispatch = useDispatch();

	const getOrders = useCallback(() => {
		dispatch(getApi(ACTIONS.GET_BILLINGS, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}`));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.billNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.billingDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.deliveryDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.status || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerId?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const column = [
		{
			title: '#',
			dataIndex: 'billNumber',
			key: 'billNumber',
			width: '5%',
			sorter: (a, b) => a?.billNumber - b?.billNumber,
			align: 'center',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Total Qty',
			dataIndex: 'totalQty',
			key: 'totalQty',
			width: '10%',
			sorter: (a, b) => a?.totalQty - b?.totalQty,
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={parseFloat(value)?.toFixed(2)}
				/>
			),
		},
		{
			title: 'Total Amount',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '10%',
			sorter: (a, b) => a?.totalAmount - b?.totalAmount,
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={parseFloat(value)?.toFixed(2)}
				/>
			),
		},
		{
			title: 'Unit Sending & Receiving Status',
			dataIndex: 'unitStatus',
			key: 'unitStatus',
			width: '15%',
			align: 'center',
			sorter: (a, b) => a?.unitStatus?.localeCompare(b?.unitStatus),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={(value)}
				/>
			),
		},
		{
			title: 'Payment Status',
			dataIndex: 'paymentStatus',
			key: 'paymentStatus',
			width: '10%',
			sorter: (a, b) => a?.paymentStatus?.localeCompare(b?.paymentStatus),
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={(value)}
				/>
			),
		},
		{
			title: 'Delivery Status',
			dataIndex: 'deliveryStatus',
			key: 'deliveryStatus',
			width: '10%',
			sorter: (a, b) => a?.deliveryStatus?.localeCompare(b?.deliveryStatus),
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={(value)}
				/>
			),
		},
	];

	// eslint-disable-next-line
	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: '5%',
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getOrders();
	}, [getOrders]);

	// useEffect(() => {
	// 	setTableData(billings);
	// }, [billings]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_BILLING === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_BILLING'));
			doIt = true;
		}
		if (doIt) {
			getOrders();
		}
	}, [globalRedux.apiStatus, getOrders, dispatch]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const getBillingItems = useCallback(
		async (values) => {
			const string = formQueryStringFromObject({
				deliveryDate: moment(values?.deliveryDate).format(DATE_FORMAT.YYYY_MM_DD),
			});
			await setFetchingData(true);
			const res = await sendGetRequest(null, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}&${string}`);
			await setTableData(res?.data?.data || []);
			await setFetchingData(false);
		},
		[setFetchingData, globalRedux?.selectedOrganization?._id]
	);

	const updateStatusItems = useCallback(
		async (values) => {
			console.log('🚀 ~ file: unit-sections-list-functional.js:255 ~ values:', values);
			await setUpdatingData(true);
			const res = await sendPostRequest({
				url: `${SERVER_IP}bill/status`,
				body: {
					countryCode: '+91',
				},
			});
			await setTableData(res?.data?.data || []);
			await setUpdatingData(false);
		},
		[setUpdatingData]
	);

	const handleClear = () => {
		setTableData([]);
		form.resetFields();
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_BILLINGS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<ComponentToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<TodayDeliveriesListPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					refreshList: getOrders,
					tableLoading,
					state,
					setState,
					editData: selectedRow,
					setSearchKey,
					rowSelection,
					selectedRowKeys,
					selectedViewRow,
					setSelectedViewRow,
					getBillingItems,
					form,
					fetchingData,
					handleClear,
					updateStatusItems,
					updatingData,
					setUpdatingData,
				}}
			/>
		</>
	);
});

export default TodayDeliveriesListFunctional;
