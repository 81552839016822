import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import { getMessaging, onMessage } from 'firebase/messaging';
import NetworkDetect from 'components/network-detect';
import { showToast } from 'helpers';
import Routes from './routes/Routes';
import { firebaseApp, getFirebaseToken } from './firebase';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';
import './css/custom.scss';
import './css/theme.scss';
// import './css/antd.css';
// import 'antd/dist/antd.min.css';

const messaging = getMessaging(firebaseApp);

function App() {
	// const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');
	// console.log('🚀 ~ file: App.js:19 ~ App ~ Notification.permission:', Notification.permission);

	useEffect(() => {
		if (Notification.permission === 'granted') {
			handleGetFirebaseToken();
		}
		onMessage(messaging, (payload) => {
			console.log('🚀 ~ file: firebase.js:46 ~ newPromise ~ payload:', payload);
			// const {
			// notification: { title, body },
			// } = payload;
			showToast('Did you know?', 'Here is something that you might like to know.');
			// toast(<ToastifyNotification title={title} body={body} />);
		});
		// onForegroundMessage()
		// 	.then((payload) => {
		// 		console.log('Received foreground message: ', payload);
		// 		const {
		// 			notification: { title, body },
		// 		} = payload;
		// 		toast(<ToastifyNotification title={title} body={body} />);
		// 	})
		// 	.catch((err) => console.log('An error occured while retrieving foreground message. ', err));
	});

	const handleGetFirebaseToken = () => {
		getFirebaseToken()
			.then((firebaseToken) => {
				console.log('Firebase token: ', firebaseToken);
				if (firebaseToken) {
					// setShowNotificationBanner(false);
				}
			})
			.catch((err) => console.error('An error occured while retrieving firebase token. ', err));
	};
	return (
		<ConfigProvider
			theme={{ hashed: false, token: { colorPrimary: '#3f8df9', borderRadius: 4, fontFamily: 'Open Sans, sans-serif', fontSize: 14 } }}>
			{/* {showNotificationBanner && (
				<div className="notification-banner">
					<span>The app needs permission to</span>
					<a href="/#" className="notification-banner-link" onClick={handleGetFirebaseToken}>
						enable push notifications.
					</a>
				</div>
			)} */}
			<NetworkDetect />
			<Routes />
			<Toaster />
			<ToastContainer hideProgressBar style={{ width: '400px' }} />
		</ConfigProvider>
	);
}

export default App;
