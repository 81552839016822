import React, { useEffect, useCallback } from 'react';
import { Input, Button, Form,  Drawer, Row, Col, } from 'antd';
// import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { putApi } from 'redux/sagas/putApiSaga';
// import { ITEM_TYPES } from 'constants/app-constants';
// import CategoryModal from 'components/category-modal';
// import UnitModal from 'components/unit-modal';

const AddItem = ({ selectedItem, showAddItemModal, showEditItemModal, setShowAddItemModal, setShowEditItemModal, setSelectedItem }) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const categories = useSelector((state) => state?.globalRedux.categories);
	const [formInstance] = Form.useForm();
	const categoryIdValue = Form.useWatch('categoryId', formInstance);
	// const inventoryValue = Form.useWatch('inventory', formInstance);
	const purchasePriceValue = Form.useWatch('purchasePrice', formInstance);
	const dispatch = useDispatch();

	useEffect(() => {
		if (categoryIdValue) {
			const profitMargin = categories?.find((category) => category?._id === categoryIdValue)?.profitMargin || 0;
			const sellingPrice = parseFloat(purchasePriceValue || 0) + parseFloat(((purchasePriceValue || 0) * profitMargin) / 100);
			formInstance.setFieldsValue({
				sellingPrice,
			});
		}
	}, [categoryIdValue, purchasePriceValue, categories, formInstance]);

	const getUnits = useCallback(() => {
		let url = `${SERVER_IP}unit?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_UNITS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getUnits();
	}, [getUnits]);

	useEffect(() => {
		if (selectedItem) {
			formInstance.setFieldsValue({
				...selectedItem,
				categoryId: selectedItem?.categoryId?._id,
				unitId: selectedItem?.unitId?._id,
			});
		}
	}, [formInstance, selectedItem]);
	
	// useEffect(() => {
	// 	if (!inventoryValue) {
	// 		formInstance.setFieldsValue({
	// 			isSerial: false,
	// 		});
	// 	}
	// }, [formInstance, inventoryValue]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.ADD_ITEM === 'SUCCESS') {
			setShowAddItemModal(false);
			formInstance.resetFields();
			doIt = true;
			dispatch(resetApiStatus('ADD_ITEM'));
		} else if (globalRedux.apiStatus.EDIT_ITEM === 'SUCCESS') {
			setShowEditItemModal(false);
			formInstance.resetFields();
			setSelectedItem(null);
			doIt = true;
			dispatch(resetApiStatus('EDIT_ITEM'));
		}
		if (doIt) {
			let url = `${SERVER_IP}item?orgId=${globalRedux.selectedOrganization._id}`;
			dispatch(getApi('GET_ITEMS', url));
		}
	}, [
		globalRedux.apiStatus,
		dispatch,
		globalRedux.selectedOrganization._id,
		formInstance,
		setSelectedItem,
		setShowAddItemModal,
		setShowEditItemModal,
	]);

	const FormCol = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 16,
		},
	};

	const addItem = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
			rate: 0,
		};
		dispatch(postApi(data, 'ADD_ITEM'));
	};

	const editItem = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
			rate: 0,
		};

		let url = `${SERVER_IP}item/${selectedItem._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_ITEM', url));
	};

	const getCategories = useCallback(() => {
		let url = `${SERVER_IP}category/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_CATEGORIES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getCategories();
	}, [getCategories]);

	useEffect(() => {
		if (!showEditItemModal) {
			setSelectedItem?.(null);
		}
	}, [showEditItemModal, setSelectedItem]);

	const isLoading = globalRedux.apiStatus.ADD_ITEM === 'PENDING' || globalRedux.apiStatus.EDIT_ITEM === 'PENDING';
	return (
		<Drawer
			title={selectedItem ? 'Edit Item' : 'Add New Item'}
			placement="right"
			width={'30%'}
			visible={showAddItemModal || showEditItemModal}
			destroyOnClose
			closable={false}
			maskClosable={false}
			okText="Save"
			footer={
				<Row gutter={[10, 0]}>
					<Col span={12}>
						<Button
							style={{ width: '100%' }}
							onClick={() => {
								showAddItemModal && setShowAddItemModal(false);
								showEditItemModal && setShowEditItemModal(false);
								formInstance.resetFields();
							}}
							danger>
							Cancel
						</Button>
					</Col>
					<Col span={12}>
						<Button
							style={{ width: '100%' }}
							loading={isLoading}
							disabled={isLoading}
							form="add-item-form"
							type="primary"
							htmlType="submit"
							// onClick={formInstance.submit}
							>
							{selectedItem ? 'Update' : 'Create'}
						</Button>
					</Col>
				</Row>
			}
			onClose={() => setShowAddItemModal(false)}>
			<Form
				// colon={false}
				id="add-item-form"
				labelAlign="left"
				className="required_in_right"
				form={formInstance}
				initialValues={{
					taxRate: '0',
				}}
				onFinish={selectedItem ? editItem : addItem}
				{...FormCol}>
				{/* <Row gutter={[10, 0]}>
					<Col span={24}>
						<Form.Item
							// label="Goods/Service"
							className="hideFormLabel"
							name="itemType"
							initialValue={'Goods'}
							rules={[
								{
									required: false,
									message: 'This Field is Required!',
								},
							]}>
							<Radio.Group options={ITEM_TYPES} optionType="button" buttonStyle="solid" />
						</Form.Item>
					</Col>
				</Row> */}
				<Row gutter={[10, 0]}>
					{/* <Col span={24}>
						<Form.Item
							label="Category"
							name="categoryId"
							getValueFromEvent={(data) => data}
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<CategoryModal />
						</Form.Item>
					</Col> */}
					<Col span={24}>
						<Form.Item
							label="Item Name"
							name="itemName"
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<Input placeholder="Item Name" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[10, 0]}>
					<Col span={24}>
						<Form.Item
							label="Item/Bar Code"
							name="itemCode"
							rules={[
								{
									required: false,
									message: 'This Field is Required!',
								},
							]}>
							<Input placeholder="Item/Barcode" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Hsn/Sac Code"
							name="hsnSac"
							rules={[
								{
									required: false,
									message: 'This Field is Required!',
								},
							]}>
							<Input placeholder="Hsn/Sac Code" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[10, 0]}>
					{/* <Col span={24}>
						<Form.Item
							label="Rate"
							name="rate"
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<Input placeholder="Rate" />
						</Form.Item>
					</Col> */}
					<Col span={24}>
						<Form.Item
							label="Description"
							name="description"
							rules={[
								{
									required: false,
									message: 'This Field is Required!',
								},
							]}>
							<Input.TextArea placeholder="Description" />
						</Form.Item>
					</Col>
					{/* <Col span={24}>
						<Form.Item
							label="MRP"
							name="mrp"
							rules={[
								{
									required: false,
									message: 'This Field is Required!',
								},
							]}>
							<Input placeholder="MRP" />
						</Form.Item>
					</Col> */}
				</Row>
				<Row gutter={[10, 0]}>
					{/* <Col span={24}>
						<Form.Item
							label="Unit"
							name="unitId"
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<UnitModal />
						</Form.Item>
					</Col> */}
					{/* <Col span={24}>
						<Form.Item
							label="GST"
							name="taxRate"
							rules={[
								{
									required: true,
									message: 'This Field is Required!',
								},
							]}>
							<Select placeholder="Select GST">
								<Select.Option value="0">GST 0%</Select.Option>
								<Select.Option value="5">GST 5%</Select.Option>
								<Select.Option value="12">GST 12%</Select.Option>
								<Select.Option value="18">GST 18%</Select.Option>
								<Select.Option value="28">GST 28%</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Row>
							<Col span={12}>
								<Form.Item
									label="Is Inventory"
									name="inventory"
									valuePropName="checked"
									labelCol={{ span: 12 }}
									wrapperCol={{ span: 12 }}
									rules={[
										{
											required: false,
											message: 'This Field is Required!',
										},
									]}>
									<Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="Is Serial"
									name="isSerial"
									valuePropName="checked"
									labelCol={{ span: 12 }}
									wrapperCol={{ span: 12 }}
									rules={[
										{
											required: false,
											message: 'This Field is Required!',
										},
									]}>
									<Switch disabled={!inventoryValue} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
								</Form.Item>
							</Col>
						</Row>
					</Col> */}
				</Row>
			</Form>
		</Drawer>
	);
};

export default AddItem;
