import React, { useEffect } from 'react';
import { Input, Button, Form, Row, Col, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { postApi } from 'redux/sagas/postApiDataSaga';

const { Option } = Select;

// let contactPersonData = {
// 	id: null,
// 	salutation: null,
// 	firstName: null,
// 	lastName: null,
// 	email: null,
// 	designation: null,
// 	mobile: null,
// };

const AddWashRemark = ({ handleClose, editWashRemark, setWashRemarkAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addAccBook = (values) => {
		let data = {
			...values,

			displayName: values.displayName,
			email: values.email,
			mobile: values.mobile,
			orgId: globalRedux?.selectedOrganization?._id,
		};

		dispatch(postApi(data, 'ADD_WASH_REMARK'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}remark/${editWashRemark._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, 'EDIT_WASH_REMARK', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_WASH_REMARK === 'SUCCESS' || globalRedux.apiStatus.EDIT_WASH_REMARK === 'SUCCESS') {
			dispatch(resetApiStatus(editWashRemark ? 'EDIT_WASH_REMARK' : 'ADD_WASH_REMARK'));
			setWashRemarkAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, editWashRemark, setWashRemarkAddModal, handleClose, dispatch]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_WASH_REMARK === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_WASH_REMARK === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Form
							name="add-staff"
							labelAlign="left"
							form={form}
							onFinish={!editWashRemark ? addAccBook : handleEdit}
							{...layer1FormCol}>
							<Form.Item
								label="Field"
								name="field"
								initialValue={editWashRemark?.field}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Select
									// showSearch
									// optionFilterProp="children"
									// filterOption={(input, option) =>
									// 	option.props.id.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
									// 	option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
									// }
									placeholder="Select remark"
									// dropdownRender={(menu) => (
									// 	<div>
									// 		{menu}
									// 		<Divider />
									// 		<div style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }} onClick={() => setShowAddItemModal(true)}>
									// 			<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
									// 				<PlusOutlined /> Item
									// 			</a>
									// 		</div>
									// 	</div>
									// )}
								>
									{[
										{
											label: 'Starch',
											value: "starch",
										},
										{
											label: 'Shades',
											value: "shades",
										},
										{
											label: 'Stain',
											value: "stain",
										},
										{
											label: 'Damage',
											value: "damage",
										},
									]?.map((remark) => (
										<Option key={remark?.value} value={remark?.value} id={remark?.value}>
											{remark?.label}
											{/* <section>
												<Col>{`${remark?.label}`}</Col>
												<Col style={{ fontWeight: 'bold' }}>{convertToIndianRupees(remark?.label)}</Col>
											</section> */}
										</Option>
									))}
								</Select>
								{/* <Input placeholder="Display name" /> */}
							</Form.Item>
							<Form.Item
								label="Additional Rate"
								name="additionalRate"
								initialValue={editWashRemark?.additionalRate}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input placeholder="Additional Rate" />
							</Form.Item>
							<Form.Item
								label="Remark"
								name="remark"
								initialValue={editWashRemark?.remark}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input placeholder="remark" />
							</Form.Item>
							<Form.Item
								// style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
								wrapperCol={{
									span: 24,
								}}>
								<Row justify="space-between">
									<Button onClick={() => setWashRemarkAddModal(false)} style={{ width: '49%' }} danger>
										Cancel
									</Button>
									<Button loading={loading} type="primary" style={{ width: '49%', marginRight: 5 }} htmlType="submit">
										{editWashRemark ? 'Update' : 'Save'}
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddWashRemark;
