import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { sendGetRequest, sendPostRequest } from 'redux/sagas/utils';
import StockReportPresentational from './stock-report-list-presentational';
import StockReportToPrint from './stock-report-to-print';
import { formQueryStringFromObject } from 'helpers';
// import { formQueryStringFromObject } from 'helpers';
// import { getApi } from 'redux/sagas/getApiDataSaga';
// import { resetApiStatus } from 'redux/reducers/globals/globalActions';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const StockReportFunctional = React.memo(() => {
	const [form] = Form.useForm();
	const [type, setType] = useState('BILL');
	const [fetchingData, setFetchingData] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState('');
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	// const dispatch = useDispatch();

	// const getOrders = useCallback(() => {
	// 	dispatch(getApi(ACTIONS.GET_BILLINGS, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}`));
	// }, [dispatch, globalRedux?.selectedOrganization?._id]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.billNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.billingDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.deliveryDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.status || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.customerId?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const column = [
		{
			title: 'Bill #',
			dataIndex: 'billNumber',
			key: 'billNumber',
			fixed: 'left',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Bill Date',
			dataIndex: 'billDate',
			key: 'billDate',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value ? moment(value).format(DATE_FORMAT.DD_MM_YYYY) : ''}
				/>
			),
		},
		{
			title: 'Bill Amount',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={parseFloat(value).toFixed(2)}
				/>
			),
		},
		{
			title: 'Mobile',
			dataIndex: 'customerId',
			align: 'center',
			render: (value) => value?.mobile
		},
		{
			title: 'Stock',
			dataIndex: 'totalQty',
			align: 'center',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
	];

	// eslint-disable-next-line
	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: '5%',
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	// useEffect(() => {
	// 	let doIt = false;
	// 	if (globalRedux.apiStatus.DELETE_BILLING === 'SUCCESS') {
	// 		dispatch(resetApiStatus('DELETE_BILLING'));
	// 		doIt = true;
	// 	}
	// 	if (doIt) {
	// 		getOrders();
	// 	}
	// }, [globalRedux.apiStatus, getOrders, dispatch]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const getBillingItems = useCallback(
		async (values) => {
			const stringValue =
				values?.stockReportBy === 'Stock In Shop'
					? 'unitStatus=Pending,Received From Unit&deliveryStatus=Pending'
					: 'unitStatus=Send To Unit';
			const string = formQueryStringFromObject({
				// fromDate: (values?.fromDate).format(DATE_FORMAT.YYYY_MM_DD),
				// toDate: (values?.toDate).format(DATE_FORMAT.YYYY_MM_DD),
				paymentStatus: values?.paymentStatus
			});
			// const res = await sendGetRequest(null, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}&${stringValue}`);
			const res = await sendGetRequest(null, `${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}&${string}&${stringValue}`);
			await setFetchingData(true);
			await setTableData(res?.data?.data || []);
			await setFetchingData(false);
			// (await res?.data?.data?.length) > 0
			// 	? setSelectedRecordToPrint({ ...form.getFieldsValue(), data: res?.data?.data })
			// 	: showToast('Sorry!', 'No data found', NOTIFICATION_STATUS_TYPES.ERROR, "top-center");
		},
		[setFetchingData, globalRedux?.selectedOrganization?._id]
	);

	const updateStatusItems = useCallback(
		async (values) => {
			console.log('🚀 ~ file: unit-sections-list-functional.js:255 ~ values:', values);
			await setUpdatingData(true);
			const res = await sendPostRequest({
				url: `${SERVER_IP}bill/status`,
				body: {
					countryCode: '+91',
				},
			});
			await setTableData(res?.data?.data || []);
			await setUpdatingData(false);
		},
		[setUpdatingData]
	);

	const handleClear = () => {
		setTableData([]);
		form.resetFields();
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_BILLINGS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<StockReportToPrint ref={componentRef} data={selectedRecordToPrint} form={form}/>
			</div>
			<StockReportPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					// refreshList: getOrders,
					tableLoading,
					state,
					setState,
					editData: selectedRow,
					setSearchKey,
					rowSelection,
					selectedRowKeys,
					selectedViewRow,
					setSelectedViewRow,
					getBillingItems,
					form,
					fetchingData,
					handleClear,
					updateStatusItems,
					updatingData,
					setUpdatingData,
					setSelectedRecordToPrint,
					type,
					setType,
				}}
			/>
		</>
	);
});

export default StockReportFunctional;
