import React, { useMemo } from 'react';
import { Modal, Row, Col } from 'antd';
import TableComponent from 'components/table-component';
import { getTheRoundOffValue } from 'helpers';

const ItemsModal = ({ setSelectedViewRow, selectedViewRow = null }) => {
	const column = [
		{
			title: '#',
			dataIndex: 'sno',
			key: 'sno',
			width: '5%',
			align: 'center',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Item',
			dataIndex: 'itemId',
			key: 'itemId',
			width: '20%',
			align: 'left',
			render: (value) => <div>{value?.itemName}</div>,
		},
		{
			title: "Starch",
			dataIndex: 'remark1Id',
			key: 'remark1Id',
			width: '20%',
			align: 'left',
			render: (value) => <div>{value?.remark}</div>,
		},
		{
			title: 'Shades',
			dataIndex: 'remark2Id',
			key: 'remark2Id',
			width: '20%',
			align: 'left',
			render: (value) => <div>{value?.remark}</div>,
		},
		{
			title: 'Quantity',
			dataIndex: 'qty',
			key: 'qty',
			width: '10%',
			align: 'right',
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			width: '10%',
			align: 'right',
			render: (value) => parseFloat(value || 0).toFixed(2),
		},
		{
			title: 'Total',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '10%',
			align: 'right',
			render: (value) => parseFloat(value || 0).toFixed(2),
		},
	];

	const totalAmount = useMemo(() => selectedViewRow?.reduce((accum, item) => accum + item.totalAmount, 0) || 0, [selectedViewRow]);
	const roundOff = getTheRoundOffValue(totalAmount);

	return (
		<Modal
			title={'Item details'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={() => setSelectedViewRow(null)}
			open={(selectedViewRow?.length || 0) > 0}
			width={'60%'}>
			<TableComponent
				className="custom-table"
				style={{ width: '100%' }}
				rowKey={(record) => record._id}
				dataSource={selectedViewRow || []}
				columns={column}
				pagination={false}
				footer={() => (
					<Row style={{ fontSize: 14 }}>
						<Col xl={21} md={21} style={{ textAlign: 'right', paddingRight: 10 }}>
							Grand Total
						</Col>
						<Col xl={1} md={1}>
							:
						</Col>
						<Col xl={2} md={2} style={{ textAlign: 'right', fontWeight: 'bold' }}>
							{parseFloat(roundOff?.value).toFixed(2)}
						</Col>
					</Row>
				)}
			/>
		</Modal>
	);
};

export default ItemsModal;
