import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDateFormat } from 'services/Utils';
import GobiLogo from 'assets/images/gobi-logo.jpeg';

const BillingToPrint = forwardRef((props, ref) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const {
		customerId: { mobile, displayName, customerNumber } = {},
		billNumber,
		billDate,
		deliveryDate,
		items,
		totalQty,
		// discount,
		totalAmount,
		paymentStatus,
		remark3Id,
		remark4Id,
	} = props?.data || {};
	return (
		<div className="bill-receipt" ref={ref}>
			<table cellPadding="0" cellSpacing="0">
				<tr className="top">
					<td colSpan="2" style={{ padding: 0, borderColor: '#000' }}>
						<table className="bordered-bottom">
							<tr>
								<td className="title" style={{ padding: '10px 0' }}>
									{/* <div>{globalRedux?.selectedOrganization?.orgName}</div> */}
									<img src={GobiLogo} alt="e-sign" style={{}} width="80%" height="60px" />
								</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr className="bill-information">
					<td colSpan="2">
						<table className="bordered-bottom">
							<tr>
								<td
									colSpan="4"
									style={{
										paddingRight: 0,
										paddingLeft: 5,
									}}>
									<div className="row">
										<div className="label" style={{ width: '30%' }}>
											Customer:
										</div>
										<div className="value" style={{ padding: 0, width: '70%', textAlign: 'left' }}>
											{displayName} - ({customerNumber || ''})
										</div>
										{/* <div
											className="row"
											style={{
												width: '50%',
												justifyContent: 'flex-end',
											}}>
											<div
												className="label"
												style={{
													textAlign: 'right',
												}}>
												Customer ID: {customerNumber}
											</div>
										</div> */}
									</div>
									<div className="row">
										<div className="label" style={{ width: '30%' }}>
											Mobile:
										</div>
										<div className="value" style={{ padding: 0, width: '70%', textAlign: 'left' }}>
											{mobile}
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td
									colSpan="4"
									style={{
										paddingRight: 0,
										paddingLeft: 5,
									}}>
									<div className="row">
										<div className="label" style={{ width: '30%', fontSize: 13 }}>
											Bill No:
										</div>
										<div className="value" style={{ padding: 0, width: '70%', fontSize: 13 }}>
											{billNumber}
										</div>
									</div>
									<div className="row">
										<div className="label" style={{ width: '30%' }}>
											Date:
										</div>
										<div className="value" style={{ padding: 0, width: '70%' }}>
											{getDateFormat(billDate)}
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '35%', borderLeft: 0 }}>Item Name</th>
											<th style={{ width: '30%' }}>Qty</th>
											<th style={{ width: '35%', borderRight: 0 }}>Total</th>
										</tr>
										{items?.map((item, index) => (
											<tr key={index}>
												<td style={{ width: '35%', borderLeft: 0, fontWeight: 'bold' }}>{item?.itemId?.itemName}</td>
												<td style={{ width: '30%' }}>{item?.qty}</td>
												<td style={{ width: '35%', borderRight: 0, fontWeight: 'bold' }}>{item?.totalAmount}</td>
											</tr>
										))}
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', border: 'none' }}>
												<div className="total-section">
													<span>Total Qty</span>
													<span>{totalQty}</span>
												</div>
											</th>
											<th style={{ width: '40%', borderTop: 0, borderBottom: 0 }}>
												<div className="discount-section">
													{/* <span>Discount:</span> */}
													<span>Net Amount:</span>
												</div>
											</th>
											<th style={{ width: '30%', border: 'none' }}>
												<div className="discount-value-section">
													{/* <span>{parseFloat(discount).toFixed(2)}</span> */}
													<span>{parseFloat(totalAmount).toFixed(2)}</span>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', borderLeft: 0, borderRight: 0, borderBottom: 0 }}>
												{remark3Id?.remark?.toLowerCase() !== 'no' && (
													<div className="total-section">
														<span style={{ fontSize: 14 }}>Stain: {remark3Id?.remark}</span>
													</div>
												)}
												{remark4Id?.remark?.toLowerCase() !== 'no' && (
													<div className="total-section">
														<span style={{ fontSize: 14 }}>Damage: {remark4Id?.remark}</span>
													</div>
												)}
												<div className="total-section">
													{/* get after 15 days billDate */}
													<div>
														Delivery Date: After 15 days{' '}
														<span className="date_box">{moment(deliveryDate).format('DD/MM')}</span>
													</div>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', borderLeft: 0, borderRight: 0, borderBottom: 0 }}>
												<div className="total-section" style={{ fontWeight: 'initial' }}>
													<span style={{ fontWeight: 'bold' }}>இயற்கை சீற்றத்திற்கு ஏற்ப Delivery Date மாறலாம்.</span>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', borderLeft: 0, borderRight: 0, borderBottom: 0 }}>
												<div className="total-section" style={{ fontWeight: 'initial' }}>
													<span style={{ fontWeight: 'bold' }}>
														துணிகளின் தன்மையை பொறுத்து அவை கிழியலாம், சுருங்கலாம், நிறம் மாறுபடலாம். நிர்வாகம் பொறுப்பல்ல.
													</span>
													<span style={{ fontWeight: 'bold' }}>மற்றும் அதற்கான இழப்பீடும் வழங்கப்பட மாட்டாது.</span>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', borderLeft: 0, borderRight: 0, borderBottom: 0 }}>
												<div className="total-section" style={{ fontWeight: 'initial' }}>
													<span style={{ fontWeight: 'bold' }}>
														துணிகளை 60 நாட்களுக்குள் பெற்றுக்கொள்ளவும். தவறினால் நிர்வாகம் பொறுப்பல்ல.
													</span>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', borderLeft: 0, borderRight: 0, borderBottom: 0 }}>
												<div
													className="total-section"
													style={{ fontWeight: 'initial', flexDirection: 'row', justifyContent: 'space-around' }}>
													<span style={{ fontWeight: 'bold' }}>தொடர்புக்கு </span>
													<span style={{ fontWeight: 'bold' }}>{globalRedux?.selectedOrganization?.contact || ''}</span>
												</div>
												{paymentStatus === 'Paid' && <span style={{ fontWeight: 'bold', fontSize: 14 }}>CASH PAID</span>}
												{paymentStatus !== 'Paid' && <span style={{ fontWeight: 'bold', fontSize: 14 }}>BALANCE</span>}
											</th>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default BillingToPrint;
